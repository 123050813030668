import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterielModule } from '../shared/modules/materiel.module';
import { PrimengModule } from '../shared/modules/primeng.module';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './components/admin.component';
import { CustomerManagementComponent } from './components/customer-management/customer-management.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { AddEditComponent } from './components/static-models/add-edit/add-edit.component';
import { StaticModelsListComponent } from './components/static-models/list/static-models-list.component';
import { StaticModelsComponent } from './components/static-models/static-models.component';
import { TrainingsListComponent } from './components/trainings/list/trainings-list.component';
import { NewTrainingComponent, NewTrainingDialog } from './components/trainings/new-training/new-training.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { SplitUpperPipe } from './pipes/split.upper.pipe';
import { StaticModelsService } from './services/static-models.service';
import { TrainingsService } from './services/trainings.service';
import { AreasComponent } from './components/areas/areas.component';
import { AreasListComponent } from './components/areas/areas-list/areas-list.component';
import { AddEditAreaComponent } from './components/areas/add-edit-area/add-edit-area.component';
import { AreaService } from './services/area.service';
import { PriceTargetComponent } from './components/price-target/price-target.component';
import { PriceTargetService } from './services/price-target.service';
import { PriceTargetFormComponent } from './components/price-target/price-target-form/price-target-form.component';
import { PriceTargetDialogComponent } from './components/areas/edit-area-customers/price-target-dialog/price-target-dialog.component';
import { SelectProductDialogComponent } from './components/price-target/select-product-dialog/select-product-dialog.component';
import { AreaCustomersComponent } from './components/areas/area-customers/area-customers.component';
import { ClustersComponent } from './components/clusters/clusters.component';
import { AddEditClusterComponent } from './components/clusters/add-edit-cluster/add-edit-cluster.component';
import { ClusterAssociationsComponent } from './components/clusters/cluster-associations/cluster-associations.component';
import { ClusterService } from '../shared/services/cluster.service';
import { CustomFieldsSettingsComponent } from './components/custom-fields-settings/custom-fields-settings.component';
import { AddEditCustomFieldSettingsComponent } from './components/custom-fields-settings/add-edit-custom-field-settings/add-edit-custom-field-settings.component';
import { CustomFieldSettingsService } from './services/customFieldSettings.service';
import { AddAdvancedRuleFormComponent } from './components/clusters/add-edit-cluster/add-advanced-rule-form/add-advanced-rule-form.component';
import { ManagePriceTargetComponent } from './components/price-target/manage-price-target/manage-price-target.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FilterDialogComponent } from './components/customer-management/filter-dialog/filter-dialog.component';

function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http) }

@NgModule({
    providers: [
        StaticModelsService,
        TrainingsService,
        AreaService,
        PriceTargetService,
        ClusterService,
        CustomFieldSettingsService
    ],
    declarations: [
        AddEditComponent,
        StaticModelsComponent,
        StaticModelsListComponent,
        NewTrainingComponent,
        NewTrainingDialog,
        SidenavComponent,
        AdminComponent,
        TrainingsListComponent,
        TrainingsComponent,
        SplitUpperPipe,
        CustomerManagementComponent,
        AddEditAreaComponent,
        PriceTargetComponent,
        PriceTargetFormComponent,
        ManagePriceTargetComponent,
        PriceTargetDialogComponent,
        SelectProductDialogComponent,
        AreasComponent,
        AreasListComponent,
        AreaCustomersComponent,
        ClustersComponent,
        AddEditClusterComponent,
        ClusterAssociationsComponent,
        CustomFieldsSettingsComponent,
        AddEditCustomFieldSettingsComponent,
        AddAdvancedRuleFormComponent,
        FilterDialogComponent
    ],
    imports: [
        MatExpansionModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        AdminRoutingModule,
        MaterielModule,
        PrimengModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ]
})
export class AdminModule { }