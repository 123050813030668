<div class="settings-table-main">
    <div class="table-content-container">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" animationDuration="0ms" *ngIf="companySettings">
            <mat-tab label="General">
                <div class="col-action-settings">
                  <div>
                    <label class="margin-top">{{'Logo'|translate}}</label>
                    <mat-card class="img-card mat-elevation-z0" *ngIf="_logoUrl">
                        <button mat-mini-fab (click)="_logoUrl=undefined"
                            class="img-icon"><mat-icon>add_a_photo</mat-icon></button>
                        <img class="img-logo" mat-card-image src="{{_logoUrl}}" alt="LOGO" />
                    </mat-card>
                    <drag-and-drop-files isMultipleFiles="false" accept="image/*" (onFileSelected)="saveLogoFileToStorga($event)"
                        *ngIf="!_logoUrl && !isLoadingSettings"></drag-and-drop-files>
                    <div *ngIf="uploadProgress">
                        {{uploadProgress.name}}
                        <mat-progress-bar mode="determinate" value="{{uploadProgress.progress}}"></mat-progress-bar>
                    </div>
                  </div>
                  <br>
                  <mat-slide-toggle [(ngModel)]="_isNoiseEnabled">
                      {{'NoiseEnabled'|translate}}
                  </mat-slide-toggle>
                  <br><br>
                  <mat-form-field appearance="fill">
                    <mat-label>{{'Language'|translate}}</mat-label>
                    <mat-select [(ngModel)]="_language">
                        <mat-option value="pt">Portuguese</mat-option>
                        <mat-option value="en">English</mat-option>
                        <mat-option value="es">Spanish</mat-option>
                        <mat-option value="ar">Arabic</mat-option>
                        <mat-option value="el">Greek</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <br>
                  <mat-form-field appearance="fill" *ngIf="countries.length > 0">
                    <mat-label>{{'Country'|translate}}</mat-label>
                    <mat-select [(ngModel)]="_country" (selectionChange)="changeCountry($event)">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <br>
                  <mat-form-field appearance="fill">
                    <mat-label>{{'Currency'|translate}}</mat-label>
                    <input [disabled]="true" type="string" matInput [value]="_currency">
                  </mat-form-field>
                </div>
            </mat-tab>
            
            <!-- <mat-tab label="WTM" [disabled]="true">
                WTM -->
                <!-- Some configurations about WTM -->
            <!-- </mat-tab> -->
            
            <mat-tab label="Mobile">
              <div class="col-action-settings" style="display: flex;">
                  <div style="width: 50%;">
                    <mat-slide-toggle [(ngModel)]="_syncOnlyEditedImages">
                        {{'SyncOnlyEditedResults'|translate}}
                    </mat-slide-toggle><br>
                    <mat-slide-toggle [(ngModel)]="_galeryPhotoEnabled">
                      {{'GaleryPhotoEnabled'|translate}}
                    </mat-slide-toggle><br>
                    <mat-slide-toggle [(ngModel)]="_singlePicturePriceOcr">
                      {{'SinglePicturePriceOcr'|translate}}
                    </mat-slide-toggle><br>
                    <mat-slide-toggle [(ngModel)]="_multiPicturePriceOcr">
                      {{'MultiPicturePriceOcr'|translate}}
                    </mat-slide-toggle><br><br>

                    <div style="display: flex;">
                      <div style="flex: 1;">
                        <b>&nbsp;&nbsp;Mobile Tabs (Full User)</b>
                        <div *ngFor="let item of defaultMobileTabs.fullUser">
                          &nbsp;&nbsp;<mat-checkbox [ngModel]="selectTabsFull[item]" (change)="removeFromListFullUser($event.checked, item)">{{item}}</mat-checkbox>
                        </div>
                      </div>
                    
                      <div style="flex: 1;">
                        <b>&nbsp;&nbsp;Mobile Tabs (Simple User)</b>
                        <div *ngFor="let item of defaultMobileTabs.simpleUser">
                          &nbsp;&nbsp;<mat-checkbox [ngModel]="selectTabsSimple[item]" (change)="removeFromListSimpleUser($event.checked, item)">{{item}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 50%;">

                    {{'Threshold'|translate}} ({{_threshold}})
                    <mat-slider id="matSlider"
                        max="100"
                        min="0"
                        step="1"
                        thumbLabel
                        [(ngModel)]="_threshold">
                    </mat-slider>
                    <div>
                      {{'ImagesQualityAndroid'|translate}} {{_imagesQualityAndroid}}%
                      <mat-slider
                          max="100"
                          min="0"
                          step="1"
                          thumbLabel
                          [(ngModel)]="_imagesQualityAndroid">
                      </mat-slider>
                    </div>
                    <div>
                      {{'ImagesQualityIOS'|translate}} {{_imagesQualityIOS}}%
                      <mat-slider
                          max="100"
                          min="0"
                          step="1"
                          thumbLabel
                          [(ngModel)]="_imagesQualityIOS">
                      </mat-slider>
                    </div>

                    <mat-form-field appearance="fill">
                      <mat-label>{{'BlurDetection'|translate}} {{'Threshold'|translate}}</mat-label>
                      <input type="number" matInput [(ngModel)]="_blurDetectionThreshold">
                    </mat-form-field><br>
                    <mat-form-field appearance="fill">
                      <mat-label>{{'OverlayBounds'|translate}}</mat-label>
                      <input type="number" matInput [(ngModel)]="_overlayBounds">
                    </mat-form-field><br>
                    <mat-form-field appearance="fill">
                      <mat-label>{{'BlurDetection'|translate}} {{'CaptureTries'|translate}}</mat-label>
                      <input type="number" matInput [(ngModel)]="_blurDetectionCaptureTries">
                    </mat-form-field><br>
                    <mat-form-field appearance="fill">
                      <mat-label>{{'OCRReadMethod'|translate}}</mat-label>
                      <mat-select [(ngModel)]="_ocrReadMethod">
                          <mat-option value="manual">{{'Manual'|translate}}</mat-option>
                          <mat-option value="automatic">{{'Automatic'|translate}}</mat-option>
                      </mat-select>
                    </mat-form-field><br>
                  </div>
              </div>
            </mat-tab>
            
            <!-- <mat-tab label="Multi Company" [disabled]="companySettings.companyType !== 'MC1'">
                <mat-form-field appearance="fill">
                    <div class="col-action-settings">
                        <mat-label>{{'Company'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectCompany">
                    </div>
                </mat-form-field>
                <mat-slide-toggle [checked]="_sharingProjectEnabled">
                    {{'Enabled'|translate}}
                </mat-slide-toggle>
                <mat-form-field appearance="fill">
                    <div class="col-action-settings">
                        <mat-label>{{'Environment'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectEnvironment">
                    </div>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <div class="col-action-settings">
                        <mat-label>{{'Organization'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectOrganization">
                    </div>
                </mat-form-field>
            </mat-tab> -->
            <mat-tab label="{{'Integration'|translate}}" [disabled]="companySettings.companyType !== 'MC1'">
              <mat-form-field appearance="fill">
                <mat-label>{{'Mc1 DataSource'|translate}}</mat-label>
                <input type="string" matInput [(ngModel)]="_mc1DataSourceUrl">
              </mat-form-field>
                <!-- <mat-form-field appearance="fill">
                    <div class="col-action-settings">
                        <mat-label>{{'Company'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectCompany">
                        <mat-label>{{'Organization'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectOrganization">
                    </div>
                </mat-form-field> -->
            </mat-tab>
            
            <mat-tab label="Training">
              <div class="col-action-settings">
                <div>
                  <mat-form-field appearance="fill">
                      <mat-label>{{'SingleTrainingSold'|translate}}</mat-label>
                      <input type="number" matInput [(ngModel)]="_trainingSettingsSingleTrainingSold" placeholder="Ex. 1">
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label>{{'TrainingQuota'|translate}}</mat-label>
                    <input type="number" matInput [(ngModel)]="_trainingSettingsTrainingQuota" placeholder="Ex. 1">
                  </mat-form-field>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{'Catalog'|translate}}">
              <div class="col-action-settings">
                <mat-slide-toggle [(ngModel)]="_showCatalogDimensions">
                  {{'ShowCatalogDimensions'|translate}}
                </mat-slide-toggle>
              </div>
            </mat-tab>
            

            <mat-tab label="Images Review">
              <br>
              <div>
                {{'MaximumNumberOfImagesInTheCatalog'|translate}}
                <mat-slider id="matSlider"
                    max="1000"
                    min="100"
                    step="10"
                    thumbLabel
                    [(ngModel)]="_maxNumberImagesInCatalog">
                </mat-slider>
              </div>
              <div>
                {{'MaximumNumberOfImagesInTheCatalog'|translate}}
                <mat-slider id="matSlider"
                    max="1000"
                    min="100"
                    step="10"
                    thumbLabel
                    [(ngModel)]="_maxNumberOfCatalogs">
                </mat-slider>
              </div>
            </mat-tab>

            <mat-tab label="Dashboard">
                <mat-accordion multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Data Studio
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <br>
                      <div class="dragdrop-list" cdkDropList (cdkDropListDropped)="dropDataStudio($event)">
                        <div class="row dragdrop-box" *ngFor="let screen of _dataSourceDataStudio" cdkDrag>
                          <div class="dragdrop-custom-placeholder" *cdkDragPlaceholder></div>
                          <div class="col" style="max-width: 50px;"><mat-icon>menu</mat-icon></div>
                          <div class="col">
                            <mat-label>Title</mat-label>
                            <input type="string" matInput [(ngModel)]="screen.title">
                          </div>
                          <div class="col">
                            <mat-label>Url</mat-label>
                            <input type="string" matInput [(ngModel)]="screen.url">
                          </div>
                          <div class="col">
                            <button mat-button (click)="removeItemFromDataStudioDatasource(screen)" cdkFocusInitial matTooltip="{{'REMOVE'|translate}}">
                              <mat-icon color="primary">remove_circle</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col">
                          <mat-form-field appearance="fill" style="width: 500px !important;">
                            <mat-label>Title</mat-label>
                            <input type="string" matInput [(ngModel)]="_dataStudioAddTitle">
                          </mat-form-field>
                        </div>
                        <div class="col">
                          <mat-form-field appearance="fill" style="width: 500px !important;">
                            <mat-label>Url</mat-label>
                            <input type="string" matInput [(ngModel)]="_dataStudioAddUrl">
                          </mat-form-field>
                        </div>
                        <button mat-button (click)="addInputDataStudio()" cdkFocusInitial matTooltip="{{'SaveChanges'|translate}}">
                          <mat-icon color="primary">add_circle</mat-icon>
                        </button>
                      </div>
                    </mat-expansion-panel>
                    
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Metabase
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-form-field appearance="fill" style="width: 500px !important;">
                        <mat-label>Secret Key</mat-label>
                        <input type="string" matInput [(ngModel)]="_metabaseSecretKey">
                      </mat-form-field>
                      <br>
                      <mat-form-field appearance="fill" style="width: 500px !important;">
                        <mat-label>Site URL</mat-label>
                        <input type="string" matInput [(ngModel)]="_metabaseSiteUrl">
                      </mat-form-field>
                      <br>
                      <div class="dragdrop-list" cdkDropList (cdkDropListDropped)="dropMetabase($event)">
                        <div class="row dragdrop-box" *ngFor="let screen of _dataSourceMetabase" cdkDrag>
                          <div class="dragdrop-custom-placeholder" *cdkDragPlaceholder></div>
                          <div class="col" style="max-width: 50px;"><mat-icon>menu</mat-icon></div>
                          <div class="col">
                            <mat-label>Title</mat-label>
                            <input type="string" matInput [(ngModel)]="screen.title">
                          </div>
                          <div class="col">
                            <mat-label>Id</mat-label>
                            <input type="string" matInput [(ngModel)]="screen.id">
                          </div>
                          <div class="col">
                            <button mat-button (click)="removeItemFromMetabaseDatasource(screen)" cdkFocusInitial matTooltip="{{'REMOVE'|translate}}">
                              <mat-icon color="primary">remove_circle</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col">
                          <mat-form-field appearance="fill" style="width: 500px !important;">
                            <mat-label>Title</mat-label>
                            <input type="string" matInput [(ngModel)]="_metabaseAddTitle">
                          </mat-form-field>
                        </div>
                        <div class="col">
                          <mat-form-field appearance="fill" style="width: 500px !important;">
                            <mat-label>Id</mat-label>
                            <input type="number" matInput [(ngModel)]="_metabaseAddId">
                          </mat-form-field>
                        </div>
                        <button mat-button (click)="addInputMetabase()" cdkFocusInitial matTooltip="{{'SaveChanges'|translate}}">
                          <mat-icon color="primary">add_circle</mat-icon>
                        </button>
                      </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-tab>
        </mat-tab-group>
        <div style="align-self: self-end; text-align-last: center;">
            <button mat-button (click)="saveSettings()" cdkFocusInitial matTooltip="{{'SaveChanges'|translate}}">
                <mat-icon color="primary">save</mat-icon>
            </button>
        </div>
    </div>
</div>