import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterielModule } from '../shared/modules/materiel.module';
import { PrimengModule } from '../shared/modules/primeng.module';
import { SharedModule } from '../shared/shared.module';
import { CompaniesListComponent } from './components/organizations/companies-management/companies-list/companies-list.component';
import { CompaniesManagementComponent } from './components/organizations/companies-management/companies-management.component';
import { CompanySettingsComponent } from './components/organizations/companies-management/company-settings/company-settings.component';
import { CreateUpdateCompanyComponent } from './components/organizations/companies-management/create-update-company/create-update-company.component';
import { CustomersComponent } from './components/organizations/companies-management/customers/customers.component';
import { CreateUpdateOrganizationComponent } from './components/organizations/create-update-organization/create-update-organization.component';
import { OrganizationSettingsComponent } from './components/organizations/organization-settings/organization-settings.component';
import { OrganizationsListComponent } from './components/organizations/organizations-list/organizations-list.component';
import { OrganizationsComponent } from './components/organizations/organizations.component';
import { SuperSidenavComponent } from './components/sidenav/super-sidenav.component';
import { SuperAdminComponent } from './components/super-admin.component';
import { SuperUsersListComponent } from './components/users/super-users-list/super-users-list.component';
import { UsersComponent } from './components/users/users.component';
import { LoginService } from './services/login.service';
import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { ApiKeysComponent } from './components/api-keys/api-keys.component';
import { AddEditApiKeyFormComponent } from './components/api-keys/add-edit-api-key-form/add-edit-api-key-form.component';
import { SuperAdminHomeComponent } from './components/super-admin-home/super-admin-home.component';

function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http) }

@NgModule({
  providers: [
    LoginService,
  ],
  declarations: [
    SuperSidenavComponent,
    SuperAdminComponent,
    UsersComponent,
    SuperUsersListComponent,
    OrganizationsComponent,
    OrganizationsListComponent,
    CreateUpdateOrganizationComponent,
    OrganizationSettingsComponent,
    CompanySettingsComponent,
    CompaniesManagementComponent,
    CompaniesListComponent,
    CreateUpdateCompanyComponent,
    CustomersComponent,
    ApiKeysComponent,
    AddEditApiKeyFormComponent,
    SuperAdminHomeComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    NgxSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SuperAdminRoutingModule,
    MaterielModule,
    PrimengModule,
    HttpClientModule,
    MatSliderModule,
    MatExpansionModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
})
export class SuperAdminModule { }
