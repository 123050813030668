// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsDate, IsNumber, IsObject, IsOptional, IsString, MaxLength, MinLength, ValidateNested } from 'class-validator'
import { ByPageRequest, CustomFields, LatLng } from '../common'
import { DateType } from '../customDecorators'

class BaseCustomer {
    @Expose() @IsString() @IsOptional() externalId?: string
    @Expose() @IsString() @MinLength(1) @MaxLength(100) name!: string
    @Expose() @IsString() @MinLength(1) category!: string
    @Expose() @IsString() type!: string
    @Expose() @IsString() @IsOptional() taxId?: string
    @Expose() @IsString() @IsOptional() customerCode?: string
    @Expose() @IsString() @IsOptional() area?: string
    @Expose() @Type(() => String) @IsArray() @IsOptional() areas?: string[]
    @Expose() @IsString() @IsOptional() chain?: string
    @Expose() @IsString() @IsOptional() territory?: string
    @Expose() @Type(() => String) @IsArray() @IsOptional() territories?: string[]
    @Expose() @IsString() @IsOptional() address?: string
    @Expose() @Type(() => String) @IsArray() @IsOptional() addresses?: string[]
    @Expose() @Type(() => LatLng) @IsOptional() location?: LatLng
    @Expose() @Type(() => LatLng) @IsOptional() locations?: LatLng[]
    @Expose() @DateType() @IsDate() @IsOptional() lastUpdate?: Date
    @Expose() @IsObject() @IsOptional() customFields?: CustomFields
    @Expose() @Type(() => String) @IsArray() @IsOptional() clusters?: string[]
}

export class CustomerWithId extends BaseCustomer {
    @Expose() @IsString() id!: string
}

export class AddCustomerRequest extends BaseCustomer { }
export class AddCustomerResponse extends CustomerWithId { }

export class UpdateCustomerRequest extends CustomerWithId { }
export class UpdateCustomerResponse extends CustomerWithId { }


export class ListCustomersModalFiltersRequest {
    @Expose() @IsString() @IsOptional() dateFrom?: string
    @Expose() @IsString() @IsOptional() dateTo?: string
}

export class ListCustomersRequest extends ByPageRequest {
    @Expose() @IsString() @IsOptional() query?: string
    @Expose() @Type(() => ListCustomersModalFiltersRequest) @IsOptional() modalFilters?: ListCustomersModalFiltersRequest
}

export class ListCustomersByAreaRequest extends ListCustomersRequest {
    @Expose() @IsString() area!: string
}

export class ListCustomersResponse {
    @Expose() @IsNumber() totalItems!: number
    @Expose() @Type(() => CustomerWithId) @ValidateNested() @IsArray() items!: CustomerWithId[]
}

export class ListCustomersCategoriesResponse {
    @Expose() @Type(() => String) @IsArray() items!: string[]
}

export class ListCustomerChainsResponse {
    @Expose() @Type(() => String) @IsArray() items!: string[]
}

export class ListCustomersTerritoriesResponse {
    @Expose() @Type(() => String) @IsArray() items!: string[]
}

export class ListCustomersAttributesResponse {
    @Expose() @Type(() => String) @IsArray() categories!: string[]
    @Expose() @Type(() => String) @IsArray() chains!: string[]
    @Expose() @Type(() => String) @IsArray() territories!: string[]
}