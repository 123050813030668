import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ListCompaniesRequest } from 'src/app/shared/api-structures/super-admin/company';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { CompaniesService } from 'src/app/super-admin/services/companies.service';
import { CreateUpdateCompanyComponent } from './create-update-company/create-update-company.component';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'companies-management',
  templateUrl: './companies-management.component.html',
  styleUrls: ['./companies-management.component.scss'],
})
export class CompaniesManagementComponent extends AutoDestroy implements AfterViewInit {
  listCompaniesRequest: ListCompaniesRequest
  organizationId: string

  constructor(private companiesService: CompaniesService,
    private dialog: MatDialog, private router: Router,
    private snackbarService: SnackbarService,
    private utilitiesService: UtilitiesService,
    private languageService: LanguageService) {
    super()
    const urlParams = this.utilitiesService.getUrlParams(window.location.href);
    if (!urlParams['organization_id']) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('InvalidUrl'))
      this.router.navigateByUrl('/super/organizations');
    }
    this.organizationId = urlParams['organization_id']
    this.listCompaniesRequest = { currentPage: 0, query: '', pageSize: 25 }
  }

  ngAfterViewInit() {
    this.companiesService.loadCompanies(this.organizationId, this.listCompaniesRequest)
  }

  refresh() {
    this.companiesService.loadCompanies(this.organizationId, this.listCompaniesRequest)
  }

  add() {
    const dialogRef = this.dialog.open(CreateUpdateCompanyComponent, {
      width: '40vw',
      data: { organizationId: this.organizationId }
    })
    dialogRef.afterClosed().subscribe(v => {
      if (v && v.isOrganizationUpdateOrCreated) {
        this.refresh()
      }
    })
  }
}
