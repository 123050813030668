<div mat-dialog-content>
  <div class="title">
    {{'TrainingBlocked'|translate}}
  </div>
  <br>
  <div class="sub-title">
    {{'ItIsNecessaryToFillOutAllTheFollowingRequirementsToConductTheTraining'|translate}}:
  </div>
  <div class="all-content">
    <div class="content">
      <mat-icon *ngIf="remainingTrainig !== 0" style="color: #36855C;">check_circle</mat-icon>
      <mat-icon *ngIf="remainingTrainig == 0" style="color: #D64040;">error</mat-icon>
      {{'HaveTrainingsAvailable'|translate}}
    </div>
    <br>
  </div>
  <div class="sub-title">
    {{'PleaseCorrectTheProblemsListedAbove'|translate}}.
  </div>
</div>
<div align="end">
  <button mat-flat-button (click)="closeDialog()" style="float: right; background-color: #215494; color: white;">{{'Confirm'|translate}}</button>
</div >