<form [formGroup]="priceTargetForm">
  <div class="container">
    <div class="product">
      <div class="title">
        <h6>{{'ProductSelection' | translate}}</h6>
      </div>
      <div class="button">
        <button class="edit-button" mat-button (click)="openProductPriceTarget()">
          <mat-icon fontSet="fa" fontIcon="fa-pen"></mat-icon>
          <span>
            {{'ChangeProduct' | translate}}
          </span>
        </button>
      </div>
      <div class="image">
        <img src="{{thumbnailUrl}}">
      </div>
      <div class="description">
        <span class="descrition-info">{{'ProductName' | translate}}</span><br>
        <span class="description-names">{{productName}}</span><br>
        <span class="descrition-info">{{'ProductId' | translate}}</span><br>
        <span class="description-names">{{productId}}</span>
      </div>
    </div>

    <div class="prices">
      <div class="title">
        <h6>{{'priceTarget' | translate}}</h6>
      </div>
      <div class="mandatories">
        <mat-form-field>
          <input matInput type="number" placeholder="{{'MinPrice' | translate}}" formControlName="minPrice">
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number" placeholder="{{'Price' | translate}}" formControlName="price" required>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number" placeholder="{{'MaxPrice' | translate}}" formControlName="maxPrice">
        </mat-form-field>
      </div>
      <div class="optionals">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'Optional' | translate}}
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div style="display: flex;">
              <mat-form-field appearance="fill">
                <mat-label>{{'Date'|translate}}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate [placeholder]="'DateFrom'|translate" formControlName="dateFrom"
                    (dateChange)="dateRangeChange($event, 'dateFrom')">
                  <input matEndDate [placeholder]="'DateTo'|translate" formControlName="dateTo"
                    (dateChange)="dateRangeChange($event, 'dateTo')">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'CustomerArea'|translate}}</mat-label>
                <mat-select formControlName="customerArea">
                  <mat-option *ngFor="let v of areaOptions" [value]="v.name">{{v.name}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'CustomerTerritory'|translate}}</mat-label>
                <mat-select formControlName="customerTerritory">
                  <mat-option *ngFor="let v of territoryOptions" [value]="v">{{v}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div><br>
            <div style="display: flex; flex-direction: row;">
              <mat-form-field>
                <mat-label>{{'CustomerCategory'|translate}}</mat-label>
                <mat-select formControlName="customerCategory">
                  <mat-option *ngFor="let v of categoryOptions" [value]="v">{{v}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'CustomerChain'|translate}}</mat-label>
                <mat-select formControlName="customerChain">
                  <mat-option *ngFor="let v of chainOptions" [value]="v">{{v}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="action-buttons">
      <button class="button-stroked" mat-stroked-button routerLink="/admin/price-target">
        <mat-icon>arrow_back</mat-icon>
        {{'Back'|translate}}
      </button>
      <button class="button-normal" type="submit" [disabled]="!priceTargetForm.valid" mat-button (click)="save()">
        {{'Save&Continue'|translate}}
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</form>