import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { distinctUntilChanged, pairwise } from "rxjs/operators";
import { MemoryStorageService } from "src/app/services/memory-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import {
  ListMobileReportsResponseItem,
  ListMobileReportsResponseStat,
  ListReportsFiltersResponse,
} from "src/app/shared/api-structures/misc/reports";
import { AutoDestroy } from "src/app/shared/base-directives/auto-destroy";
import {
  ColumnSettings,
  GenericTableComponent,
} from "src/app/shared/components/generic-table/generic-table.component";
import {
  getCompanyType
} from "src/app/shared/services/companyType.service";
import { ComplianceReportService } from "src/app/shared/services/complianceReport.service";
import { LanguageService } from "src/app/shared/services/language.service";
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
  animations: [
    trigger("iconFlip", [
      state(
        "normal",
        style({
          transform: "none",
        })
      ),
      state(
        "flipped",
        style({
          transform: "rotate(180deg)",
        })
      ),
      transition("normal => flipped", animate("400ms ease-in")),
      transition("flipped => normal", animate("400ms ease-out")),
    ]),
    trigger("cardShrink", [
      state(
        "normal",
        style({
          transform: "scaleY(1)",
          height: "*",
        })
      ),
      state(
        "shrunken",
        style({
          transform: "scaleY(0)",
          height: 0,
        })
      ),
      transition("normal => shrunken", animate("400ms ease-in")),
      transition("shrunken => normal", animate("400ms ease-out")),
    ]),
  ],
})
export class ReportsComponent extends AutoDestroy implements OnInit {
  displayedColumns = [
    "id",
    "lastUpdate",
    "createdAt",
    "syncedAt",
    "userId",
    "userName",
    "planogramName",
    "territory",
    "category",
    "customerId",
    "customerName",
    "audit",
    "linkToReport",
  ];
  columnsSettings: ColumnSettings[] = [
    { column: "syncedAt", label: "syncedAt", type: "date" },
    { column: "linkToReport", label: "link", type: "custom" },
    { column: "audit", label: "audit", type: "custom" },
  ];
  dataForFilters: ListReportsFiltersResponse;
  fullData: ListMobileReportsResponseItem[] = [];
  data: ListMobileReportsResponseItem[] = [];
  hasNext = false;
  pageSize = 50;
  currentPage = 0;
  stats: ListMobileReportsResponseStat[] = [];
  sortBy: string;
  sortDirection: string;
  showFilters = true;

  filtersForm: FormGroup = new FormGroup({
    startDateCreatedAt: new FormControl(),
    endDateCreatedAt: new FormControl(),
    startDateSyncedAt: new FormControl(),
    endDateSyncedAt: new FormControl(),
    startDateLastUpdate: new FormControl(),
    endDateLastUpdate: new FormControl(),
    territories: new FormControl(),
    customerCategories: new FormControl(),
    customerNames: new FormControl(),
    userNames: new FormControl(),
    planogramNames: new FormControl(),
    audit: new FormControl(),
  });

  selectAll: { [key: string]: boolean } = {};
  selectMap: {
    [key: string]: { data: any[]; controlName: string; translate: string };
  } = {      territories: {
    data: [],
    controlName: "territories",
    translate: "CustomerTerritory",
  },
  customerCategories: {
    data: [],
    controlName: "customerCategories",
    translate: "CustomerCategory",
  },
  customerNames: {
    data: [],
    controlName: "customerNames",
    translate: "CustomerName",
  },
  userNames: {
    data: [],
    controlName: "userNames",
    translate: "UserName",
  },
  planogramNames: {
    data: [],
    controlName: "planogramNames",
    translate: "PlanogramName",
  },
  audit: {
    data: [],
    controlName: "audit",
    translate: "Audit",
  },};

  iconFlipState = "flipped";
  cardShrinkState = "shrunken";

  constructor(
    private complianceReportService: ComplianceReportService,
    private languageService: LanguageService,
    private memoryStorageService: MemoryStorageService,
    private snackBar: SnackbarService,
    private utiliesService: UtilitiesService
  ) {
    super();
  }
  @ViewChild("table")
  table: GenericTableComponent<ListMobileReportsResponseItem>;

  async ngOnInit() {
    this.resetFilters();
    this.listMobileReports();
    this.dataForFilters =
      await this.complianceReportService.listMobileReportsFilters();
    this.subscriptions.push(
      this.filtersForm.valueChanges
        .pipe(
          distinctUntilChanged(),
          pairwise() // gets a pair of old and new value
        )
        .subscribe(([oldValue, newValue]) => {
          if (
            newValue.startDate !== oldValue.startDate ||
            newValue.endDate !== oldValue.endDate
          ) {
            if (
              newValue.endDate?.getTime() - newValue.startDate?.getTime() >
              1000 * 60 * 60 * 24 * 32
            ) {
              alert(
                this.languageService.translateSync(
                  "DateRangeMustBeLessThanAMonth"
                )
              );
            }
          }
        })
    );

    this.selectMap = {
      territories: {
        data: this.dataForFilters?.customerTerriroties,
        controlName: "territories",
        translate: "CustomerTerritory",
      },
      customerCategories: {
        data: this.dataForFilters?.customerCategories,
        controlName: "customerCategories",
        translate: "CustomerCategory",
      },
      customerNames: {
        data: this.dataForFilters?.customerNames,
        controlName: "customerNames",
        translate: "Customers",
      },
      userNames: {
        data: this.dataForFilters?.userNames,
        controlName: "userNames",
        translate: "UserName",
      },
      planogramNames: {
        data: this.dataForFilters?.planogramNames,
        controlName: "planogramNames",
        translate: "PlanogramName",
      },
      audit: {
        data: this.dataForFilters?.audit,
        controlName: "audit",
        translate: "Audit",
      },
    };
  }

  toggleAnimations() {
    this.iconFlipState = this.iconFlipState === "normal" ? "flipped" : "normal";
    this.cardShrinkState = this.cardShrinkState === "normal" ? "shrunken" : "normal";
  }

  paginate(page: number) {
    this.currentPage = page;
    this.data = this.fullData.slice(
      this.currentPage * this.pageSize,
      this.currentPage * this.pageSize + this.pageSize
    );
    this.hasNext =
      this.fullData.length > this.currentPage * this.pageSize + this.pageSize;
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    setTimeout(() => {
      this.table.calcMaxHeight();
    });
  }

  resetFilters() {
    this.filtersForm.reset();
    const today = new Date();

    this.filtersForm
      .get("startDateLastUpdate")
      .setValue(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
      );
    this.filtersForm.get("endDateLastUpdate").setValue(today);
  }

  async listMobileReports() {
    const filters = this.filtersForm.value;
    const allDatesNull = [
      'startDateCreatedAt',
      'endDateCreatedAt',
      'startDateSyncedAt',
      'endDateSyncedAt',
      'startDateLastUpdate',
      'endDateLastUpdate'
    ].every(prop => filters[prop] === null);
  
    if (allDatesNull) {
      this.snackBar.openSnackBar(3000, this.languageService.translateSync('PleaseFillInOneOfTheDateFields'))
      return;
    }

    let lsCustomerNames:string[] = []
    if (filters.customerNames) {
      filters.customerNames.forEach(item => {
        const [first, second] = item.split(';');
        if (first) {
          lsCustomerNames.push(first);
        } else if (second) {
          lsCustomerNames.push(second);
        }
      });
      filters.customerNames = lsCustomerNames;
    }
  
    const { items, stats } = await this.complianceReportService.listMobileReports({ ...filters });
    this.fullData = items.map((item) => ({
      ...item,
      linkToReport: this.getReportLink(item.id),
    }));
    this.paginate(0);
    this.stats = stats;
  }

  onSort(sort) {
    const d = this.fullData.sort((a, b) => {
      const direction = sort.direction === "asc" ? 1 : -1;
      if (a[sort.active] < b[sort.active]) {
        return -1 * direction;
      } else {
        return direction;
      }
    });
    this.fullData = [...d];
    this.paginate(this.currentPage);
  }

  getReportLink(reportId: string) {
    let link = `/overlay_adjustments/${reportId}`;
    if (getCompanyType() === "MC1") {
      link += `?token=${this.memoryStorageService.token}`;
    }
    return link;
  }

  export() {
    const csv = this.utiliesService.jsonToCsv(
      this.fullData.map((v) => {
        const item = { ...v, audit: v.audit ? "Yes" : "No" };
        delete item["linkToReport"];
        return item;
      })
    );

    const blob = new Blob([csv], { type: "text/csv" });
    this.utiliesService.saveFile(blob, "report.csv");
  }

  selectAllSwitch(selectName: string, input: HTMLInputElement) {
    const { data, controlName } = this.selectMap[selectName];
    const control = this.filtersForm.get(controlName);
    this.selectAll[selectName] = !this.selectAll[selectName];

    let selectedOptions = this.selectAll[selectName] ? data.slice() : [];

    if (input && input.value) {
      selectedOptions = selectedOptions.filter((option) =>
        option.toLowerCase().includes(input.value.toLowerCase())
      );
    }

    if (this.selectAll[selectName]) {
      const selectAllOptionIndex = selectedOptions.findIndex(
        (option) => option === "select-all"
      );
      if (selectAllOptionIndex === -1) {
        selectedOptions.push("select-all");
      }
    }

    control.setValue(selectedOptions);
  }
}
