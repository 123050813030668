<div class="association-table-main">
    <div class="table-content-container">
        <div class="association-table-header">
            <div *ngIf="associationType === 'customer'">
                <div class="table-title">
                    {{customerName}}{{' - '}}{{'AssociatedUsers'|translate}}
                </div>
            </div>

            <div class="mat-elevation-z4">
                <add-associated-user [idType]="associationType" [id]="id" (onUserSelected)="onUserSelected($event)"
                    (close)="closeAddUser()" class="associate-user">
                </add-associated-user>
            </div>
        </div>

        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="dataSource" matSort class="custom-table" (matSortChange)="sortData($event)">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Name'|translate}}</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> {{'Email'|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.email }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>{{'Role'|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.role }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="deleteIcon">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let row">
                        <button matTooltip="{{'Delete'|translate}}" mat-button (click)="ondeleteClick(row.id)">
                            <mat-icon style="color:#919191;transform: scale(0.9);">delete_outline</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
            <mat-paginator [length]="totalAssociatedUsers" [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="getAssociatedUsers(paginator.pageIndex,paginator.pageSize, filter)">
            </mat-paginator>
        </div>
    </div>
    <p-dialog [(visible)]="isShowConfirmDeleteDialog">
        <p-header>{{ 'Warning' | translate }}</p-header>
        <p>{{'DeleteUserAssociationCaution'|translate}}{{associationType}}{{'?'}}</p>
        <div class="dialog-button-container">
            <button mat-flat-button color="primary" (click)="isShowConfirmDeleteDialog=false">{{'Cancel'
                |translate}}</button>
            <button mat-flat-button (click)="deleteUserCustomer()">{{'Delete' | translate}}</button>
        </div>
    </p-dialog>
</div>