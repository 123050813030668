import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, HostListener } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Dal } from "src/app/dal/dal";
import { DataService } from "src/app/services/data.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { LanguageService } from "src/app/shared/services/language.service";
import _ from "underscore";
import { PlanogramWithId } from "src/app/shared/api-structures/misc/planogram";

@Component({
  selector: "planogram-info",
  templateUrl: "./planogram-info.component.html",
  styleUrls: ["./planogram-info.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "visible",
        style({
          height: "*",
          opacity: "1",
        })
      ),
      state(
        "hidden",
        style({
          height: "0",
          opacity: "0",
          display: "hidden",
        })
      ),
      transition("visible => hidden", animate("500ms ease-out")),
      transition("hidden => visible", animate("500ms ease-in")),
    ]),
    trigger("flipAnimation", [
      state(
        "notFlipped",
        style({
          transform: "rotateX(0deg)",
        })
      ),
      state(
        "flipped",
        style({
          transform: "rotateX(180deg)",
        })
      ),
      transition("notFlipped <=> flipped", animate("500ms ease")),
    ]),
  ],
})
export class PlanogramInfoComponent implements AfterViewInit {
  shelfs: any[] = [];
  tableStates: string[];
  isFlipped: string[];
  isPrint = false;
  planogram: PlanogramWithId;
  urlPlanogramId: string;
  shelfHeight: number;
  currentEquipment: any;
  render: boolean = false;
  imageHeight: number

  @HostListener("window:afterprint", ["$event"])
  onAfterPrint(event: Event) {
    this.isPrintSwitch();
  }

  isPrintSwitch() {
    this.isPrint = !this.isPrint;
  }

  print() {
    this.isPrintSwitch();
    setTimeout(() => {
      window.print();
    }, 0);
  }

  toggleAnimation(index: number) {
    this.tableStates[index] =
      this.tableStates[index] === "visible" ? "hidden" : "visible";
    this.isFlipped[index] =
      this.isFlipped[index] === "notFlipped" ? "flipped" : "notFlipped";
  }

  constructor(
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private utilitiesService: UtilitiesService,
    public dataService: DataService,
    private router: Router,
    private dal: Dal,
    public datepipe: DatePipe,
    private languageService: LanguageService
  ) {}

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };
    return date.toLocaleDateString(undefined, options);
  }

  capitalizeFirstLetter(text: string): string {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  async ngAfterViewInit() {
    this.handleUrlParams();

    if (!this.isValidPlanogramId()) {
      this.handleInvalidPlanogram();
      return;
    }

    this.planogram = await this.getPlanogram();

    this.shelfs = this.planogram?.structure.areas.filter(
      area => area.areaType !== "seperator"
    );

    const productsPromise = this.searchProducts(1000);
    const imageUrl = this.planogram?.imageUrl;
    const loadImagePromise = this.loadImageHeightAndUpdateShelfHeight(imageUrl);

    const [products] = await Promise.all([
      productsPromise,
      loadImagePromise,
      this.getEquipments(),
    ]);

    this.updateShelfItems(products);
    this.setEquipment();

    this.tableStates = Array(this.shelfs.length).fill("visible");
    this.isFlipped = Array(this.shelfs.length).fill("notFlipped");
    this.render = true;
  }

  private async loadImageHeightAndUpdateShelfHeight(
    imageUrl: string
  ): Promise<void> {
    if (imageUrl) {
      this.imageHeight = this.shelfs.length > 7 ? 800 : 500;
      this.shelfHeight = this.imageHeight / this.shelfs.length;
    }
  }

  private handleUrlParams() {
    const urlParams = this.utilitiesService.getUrlParams(window.location.href);
    this.urlPlanogramId = urlParams["planogram_id"];
  }

  private isValidPlanogramId(): boolean {
    return !!this.urlPlanogramId;
  }

  private handleInvalidPlanogram() {
    this.snackbarService.openSnackBar(
      2000,
      this.languageService.translateSync("InvalidUrl")
    );
    this.router.navigateByUrl("/");
  }

  private async getPlanogram() {
    return await this.dal.getPlanogram(this.urlPlanogramId);
  }

  private async searchProducts(amount: number) {
    return await this.dal.searchProductsForPlanogramPlanningDifferingCatalog(
      true,
      0,
      amount,
      [],
      true,
      {}
    );
  }

  private updateShelfItems(products: any) {
    for (const shelf of this.shelfs || []) {
      const combinedItemsMap = new Map<string, number>();
  
      for (const shelfItem of shelf.items || []) {
        const productId = shelfItem.productId;
        const productName = shelfItem.name;
        const key = productId !== undefined ? productId : productName;
  
        if (!combinedItemsMap.has(key)) {
          combinedItemsMap.set(key, 1);
        } else {
          combinedItemsMap.set(key, combinedItemsMap.get(key) + 1);
        }
        this.updateShelfItemWithMatchingProduct(shelfItem, products);
      }
  
      combinedItemsMap.forEach((quantity, key) => {
        const firstItemIndex = shelf.items.findIndex(
          (item) => item.productId === key || item.name === key
        );
        const firstItem = shelf.items[firstItemIndex];
  
        firstItem.quantity = quantity;
        shelf.items = shelf.items.filter(
          (item) => (item.productId !== key && item.name !== key) || item === firstItem
        );
      });
    }
  }
  
  private updateShelfItemWithMatchingProduct(shelfItem: any, products: any) {
    const matchingItem = products.items.find(
      resItem => resItem.productId === shelfItem.productId || resItem.name === shelfItem.name
    );
    if (matchingItem) {
      this.updateShelfItemDetails(shelfItem, matchingItem);
    } else {
      shelfItem.category = shelfItem.categoryId;
      shelfItem.brand = shelfItem.brandId;
    }
  }

  private updateShelfItemDetails(shelfItem: any, matchingItem: any) {
    shelfItem.name = matchingItem.name;
    shelfItem.ean = matchingItem.ean;
    shelfItem.category = matchingItem.category ?? shelfItem.categoryId;
    shelfItem.brand = matchingItem.brand;
    shelfItem.currentImagesCatalog = matchingItem.imageCatalogs[0];
    shelfItem.imageCatalogs = matchingItem.imageCatalogs;
  }

  private getDistance(shelf: any, direction: string, index: number) {
    if (direction === "ground") {
      return shelf.areaType === "horizontal"
        ? shelf.rect.height * (this.shelfs.length - (index + 1)) +
            (this.currentEquipment?.shelfSeparatorHeight || 0) *
              (this.shelfs.length - (index + 1))
        : 0;
    } else {
      return shelf.areaType === "vertical"
        ? shelf.rect.width * (this.shelfs.length - (index + 1)) +
            (this.currentEquipment?.shelfSeparatorHeight || 0) *
              (this.shelfs.length - (index + 1))
        : 0;
    }
  }

  setEquipment() {
    if (this.planogram.equipmentId) {
      this.currentEquipment = this.utilitiesService.cloneObject(
        _.find(
          this.dataService.equipments,
          e => e.id == this.planogram.equipmentId
        )
      );
    }
  }

  getEquipments = async () => {
    const equipmentsRes = await this.dal.getEquipments([]);
    this.dataService.equipments = _.sortBy(equipmentsRes, "name");
  };
}
