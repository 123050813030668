import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClusterService } from 'src/app/shared/services/cluster.service';

@Component({
  selector: 'planogram-associations',
  templateUrl: './planogram-associations.component.html',
  styleUrls: ['./planogram-associations.component.scss']
})
export class PlanogramAssociationsComponent {
  planogramName: string
  planogramId: string

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.planogramName = data.name
    this.planogramId = data.id
  }

}