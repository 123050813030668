import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PriceTargetService } from 'src/app/admin/services/price-target.service';
import { AreaService } from 'src/app/admin/services/area.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Router } from '@angular/router';
import { SelectProductDialogComponent } from '../select-product-dialog/select-product-dialog.component';

@Component({
  selector: 'app-manage-price-target',
  templateUrl: './manage-price-target.component.html',
  styleUrls: ['./manage-price-target.component.scss']
})
export class ManagePriceTargetComponent implements OnInit, OnDestroy {
  productDialogRef: MatDialogRef<SelectProductDialogComponent, any>
  areaOptions = []
  chainOptions = []
  categoryOptions = []
  territoryOptions = []
  productName: string
  productId: string
  thumbnailUrl: string
  priceTargetId: string
  mode: 'edit' | 'new' = 'edit'
  priceTargetForm = new FormGroup({
    productId: new FormControl(null),
    productName: new FormControl(null),
    thumbnailUrl: new FormControl(null),
    minPrice: new FormControl(null),
    price: new FormControl(null),
    maxPrice: new FormControl(null),
    customerSalesTerritory: new FormControl(null),
    customerCategory: new FormControl(null),
    customerChain: new FormControl(null),
    customerArea: new FormControl(null),
    customerTerritory: new FormControl(null),
    dateFrom: new FormControl(null),
    dateTo: new FormControl(null),
  })
  constructor(private utilitiesService: UtilitiesService,
    private priceTargetService: PriceTargetService,
    private areaService: AreaService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private snackBar: SnackbarService,
    private router: Router) { }

  ngOnInit() {
    let urlParams = this.utilitiesService.getUrlParams(window.location.href)
    this.mode = urlParams['mode']
    this.checkMode(urlParams['mode'], urlParams['id'])
  }

  ngOnDestroy() {
    this.reset()
  }

  save() {
    if (!this.validateForm()) {
      this.snackBar.openSnackBar(4000, this.languageService.translateSync('PriceMustBeBetweenMinPriceAndMaxPrice'))
      return
    }
    if (this.mode === 'edit') {
      this.priceTargetService.updatePriceTarget({ id: this.priceTargetId,
        data: {
          maxPrice: this.priceTargetForm.controls.maxPrice.value,
          minPrice: this.priceTargetForm.controls.minPrice.value,
          price: this.priceTargetForm.controls.price.value,
          productId: this.priceTargetForm.controls.productId.value,
          productName: this.priceTargetForm.controls.productName.value,
          thumbnailUrl: this.priceTargetForm.controls.thumbnailUrl.value,
          customerArea: this.priceTargetForm.controls.customerArea.value,
          customerCategory: this.priceTargetForm.controls.customerCategory.value,
          customerChain: this.priceTargetForm.controls.customerChain.value,
          customerTerritory: this.priceTargetForm.controls.customerTerritory.value,
          dateFrom: this.priceTargetForm.controls.dateFrom.value,
          dateTo: this.priceTargetForm.controls.dateTo.value,
        }
      }).then(async () => {
        this.snackBar.openSnackBar(3000, this.languageService.translateSync('PriceTargetEdited'))
        setTimeout(() => {
          this.router.navigateByUrl('/admin/price-target')
        }, 3000);
      })
    }else {
      this.priceTargetService.addPriceTarget({
        maxPrice: this.priceTargetForm.controls.maxPrice.value,
        minPrice: this.priceTargetForm.controls.minPrice.value,
        price: this.priceTargetForm.controls.price.value,
        productId: this.priceTargetForm.controls.productId.value,
        productName: this.priceTargetForm.controls.productName.value,
        thumbnailUrl: this.priceTargetForm.controls.thumbnailUrl.value,
        customerArea: this.priceTargetForm.controls.customerArea.value,
        customerCategory: this.priceTargetForm.controls.customerCategory.value,
        customerChain: this.priceTargetForm.controls.customerChain.value,
        customerTerritory: this.priceTargetForm.controls.customerTerritory.value,
        dateFrom: this.priceTargetForm.controls.dateFrom.value,
        dateTo: this.priceTargetForm.controls.dateTo.value,
      }).then(async () => {
        this.snackBar.openSnackBar(3000, this.languageService.translateSync('PriceTargetAdded'))
        setTimeout(() => {
          this.router.navigateByUrl('/admin/price-target')
        }, 3000);
      })
    }
  }

  reset() {
    this.priceTargetForm.reset()
    this.productId = undefined
    this.productName = undefined
  }

  checkMode(mode: 'new' | 'edit', priceTarget?: any) {
    if (mode === 'new' && !priceTarget) {
      this.loadOptions()
      this.openProductPriceTarget()
      return
    }
    this.priceTargetId = priceTarget
    this.loadData(priceTarget)
  }

  selectProduct(row: { id: string, name: string, thumbnailUrl: string }) {
    this.productDialogRef.close()
    this.priceTargetForm.controls.productId.setValue(row.id)
    this.priceTargetForm.controls.productName.setValue(row.name)
    this.priceTargetForm.controls.thumbnailUrl.setValue(row.thumbnailUrl)
    this.productName = row.name
    this.productId = row.id
    this.thumbnailUrl = row.thumbnailUrl
  }

  openProductPriceTarget() {
    this.productDialogRef = this.dialog.open(SelectProductDialogComponent, {
      data: {
        onSelect: this.selectProduct.bind(this)
      }
    })
    this.productDialogRef.afterClosed().subscribe(res => {
      if (this.mode === 'new' && !this.productId) {
        this.router.navigateByUrl('/admin/price-target')
        return
      }
    })
  }

  dateRangeChange(event, type: 'dateFrom' | 'dateTo') {
    if (type === 'dateFrom') {
      this.priceTargetForm.controls.dateFrom.setValue(event.value)
      return
    }
    this.priceTargetForm.controls.dateTo.setValue(event.value)
  }

  validateForm() {
    const price = this.priceTargetForm.controls.price.value
    const minPrice = this.priceTargetForm.controls.minPrice.value
    const maxPrice = this.priceTargetForm.controls.maxPrice.value
    if ((minPrice !== undefined && minPrice > price) || (price !== undefined && price > maxPrice)) {
      return false
    }
    return true
  }

  async loadOptions() {
    const areas = await this.areaService.listAreas({currentPage: 0, pageSize: 50})
    const attr = await this.customerService.listCustomersAttributes()
    this.chainOptions = attr.chains
    this.categoryOptions = attr.categories
    this.territoryOptions = attr.territories
    areas.items.map(area => {
      this.areaOptions.push({id: area.id, name: area.name})
    })
  }

  async loadData(priceTargetId: string) {
    await this.loadOptions()
    const priceTarget = (await this.priceTargetService.fetchPriceTarget({currentPage: 0, pageSize: 10, textFilter: priceTargetId})).items[0]
    this.productName = priceTarget.productName
    this.productId = priceTarget.productId
    this.thumbnailUrl = priceTarget.thumbnailUrl
    this.priceTargetForm.controls.productId.setValue(priceTarget.productId)
    this.priceTargetForm.controls.productName.setValue(priceTarget.productName)
    this.priceTargetForm.controls.thumbnailUrl.setValue(priceTarget.thumbnailUrl)
    this.priceTargetForm.controls.customerArea.setValue(priceTarget.customerArea)
    this.priceTargetForm.controls.customerCategory.setValue(priceTarget.customerCategory)
    this.priceTargetForm.controls.customerChain.setValue(priceTarget.customerChain)
    this.priceTargetForm.controls.customerTerritory.setValue(priceTarget.customerTerritory)
    this.priceTargetForm.controls.dateFrom.setValue(priceTarget.dateFrom)
    this.priceTargetForm.controls.dateTo.setValue(priceTarget.dateTo)
    this.priceTargetForm.controls.minPrice.setValue(priceTarget.minPrice)
    this.priceTargetForm.controls.price.setValue(priceTarget.price)
    this.priceTargetForm.controls.maxPrice.setValue(priceTarget.maxPrice)
  }
}
