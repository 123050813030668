import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CompaniesService } from '../../services/companies.service';
import { OrganizationsService } from '../../services/organizations.service';
import { SuperUserManagementService } from '../../services/super-user-management.service';
import { ListOrganizationsRequest } from 'src/app/shared/api-structures/super-admin/organization';
import { Dal } from 'src/app/dal/dal';
import { GetUserInfoResponse } from 'src/app/shared/api-structures/admin/user';
import StandaloneNavbarItemModel from 'src/app/shared/models/standalone-navbar-item.model';
import { Router } from '@angular/router';

interface CardItem {
  title: string;
  amount: number;
  icon: string;
  dataSource: any[];
}

@Component({
  selector: 'app-super-admin-home',
  templateUrl: './super-admin-home.component.html',
  styleUrls: ['./super-admin-home.component.scss']
})
export class SuperAdminHomeComponent implements AfterViewInit {

  organizationRequest: ListOrganizationsRequest

  nextPageToken: string
  pageSize: number = 4;
  totalOrganizations: number;
  orgs: any
  comps: any
  users: any
  totalItems: number
  totalCompanies: number
  orgId: string
  userInfo: GetUserInfoResponse | null

  totalUsers: number;
  cardItems: CardItem[] = [
    {
      title: 'Organizations', amount: 0, icon: 'group_work',
      dataSource: [
      ]
    },
    {
      title: 'Companies', amount: 0, icon: 'business',
      dataSource: [
      ]
    },
    {
      title: 'Users', amount: 0, icon: 'groups',
      dataSource: [
      ]
    }
  ];
  displayedColumns: string[] = this.getColumns(this.cardItems[0].dataSource);


  constructor(
    private superUserManagementService: SuperUserManagementService,
    private companiesService: CompaniesService,
    private organizationsService: OrganizationsService,
    private dal: Dal,
    private router: Router
  ) { }

  ngAfterViewInit(): void {

    Promise.all([
      this.loadOrganization(() => {
        this.loadCompanies()
      }),
      this.loadUsers()
    ])
  }

  async loadCompanies() {
    this.companiesService.listCompanies$.subscribe(_comps => {
      this.comps = _comps.items
      const mapComps = this.comps.map(({ id, totalCustomers }) => ({ id, totalCustomers })).slice(0, 4)
      this.cardItems[1].dataSource = mapComps;
    })
  }

  async loadOrganization(callback) {
    this.organizationRequest = { currentPage: 0, query: '', pageSize: 999 }
    this.organizationsService.loadOrganizations(this.organizationRequest)

    this.organizationsService.listOrganizations$.subscribe(orgs => {
      this.orgs = orgs.items
      this.totalOrganizations = orgs.totalItems
      this.cardItems[0].amount = this.totalOrganizations
      const mapOrgs = this.orgs.map(({ name, companies }) => ({ name, companies })).slice(0, 4)
      this.cardItems[0].dataSource = mapOrgs;
      this.cardItems[1].amount = this.orgs.reduce((acc, item) => acc + item.companies, 0);
      const filteredOrgs = this.orgs.filter(org => org.companies > 4);
      this.orgId = filteredOrgs.length > 0 ? filteredOrgs.reduce((max, org) => org.companies > max.companies ? org : max, filteredOrgs[0]).id : undefined;
      this.companiesService.loadCompanies(this.orgId, this.organizationRequest)
      callback()
    })

  }

  async loadUsers() {
    const users = await this.superUserManagementService.getUsers({ maxPage: 999, token: this.nextPageToken });
    this.users = users.items;
    console.log(users)
    this.totalUsers = users.items.length
    this.cardItems[2].amount = this.totalUsers;
    this.cardItems[2].dataSource = this.users.map(({ name, email }) => ({ name, email })).slice(0, 4);
    this.dal.getUserInfo().then(userInfo => {
      this.userInfo = userInfo
    })
  }

  getColumns(data: any[]): string[] {
    const columns: string[] = [];
    if (data.length > 0) {
      for (const key in data[0]) {
        columns.push(key);
      }
    }
    return columns;
  }

  getDisplayColumn(column: string): string {
    const keyMap: { [key: string]: string } = {
      totalCustomers: 'Users',
      id: 'Name'
    };
    return keyMap[column] || column;
  }

  async getUsers(nextPageToken: string) {
    const res = await this.superUserManagementService.getUsers({ maxPage: this.pageSize, token: nextPageToken })
    if (res.nextPageToken) {
      this.nextPageToken = res.nextPageToken
    }
  }

  navigateToPage(tableTitle: string) {
    const tableRouteMap = {
      Organizations: '/super/organizations',
      Users: '/super/userManagement'
    }
    this.router.navigate([tableRouteMap[tableTitle]]);
  }

  greaterThanMax(amount :number) {
    return amount > 999
  }

}
