// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsIn, IsNumber, IsOptional, IsString, Matches, MinLength, ValidateNested } from 'class-validator'
import { BaseStructure, ByPageRequest, CompanyType, CompanyTypeArray, Ctx } from '../common'
import { IsStrictCtx } from '../customDecorators'

export class Company extends BaseStructure {
    @Expose() @IsString() organizationId!: string
    @Expose() @IsString() environmentId!: string
    @Expose() @IsNumber() totalCustomers!: number
}

export class ListCompaniesRequest extends ByPageRequest {
    @Expose() @IsString() @IsOptional() query?: string
}

export class ListCompaniesResponse {
    @Expose() @IsNumber() totalProducts!: number
    @Expose() @Type(() => Company) @ValidateNested() @IsArray() items!: Company[]
}

const regionRegex = /^[a-z\d]+(-[a-z\d]+)?$/i // EU | us-central1
export class CreateCompanyRequest {
    @Expose() @IsString() @IsIn(CompanyTypeArray) companyType!: CompanyType
    @Expose() @IsStrictCtx() ctx!: Ctx
    @Expose() @IsString() @MinLength(1) @Matches(/^[a-z\d]+([-][a-z\d]+)*--[a-z\d]+([-][a-z\d]+)*$/) resourceName!: string // wtm-mdlz-br--cr02-my-test | mdlz--br
    @Expose() @IsString() @MinLength(1) @Matches(/^[a-z\d]+([-][a-z\d]+)*$/) env!: string
    @Expose() @IsString() @MinLength(1) @Matches(regionRegex) firestoreRegion!: string // https://cloud.google.com/firestore/docs/locations
    @Expose() @IsString() @MinLength(1) @Matches(regionRegex) bucketRegion!: string // https://cloud.google.com/storage/docs/locations
    @Expose() @IsString() @MinLength(1) @Matches(regionRegex) bigqueryRegion!: string // https://cloud.google.com/bigquery/docs/locations
}

export class CreateCompanyResponse {
    @Expose() @IsBoolean() @IsOptional() isResourceExists?: boolean
    @Expose() @IsString() @IsOptional() firestoreRegion?: string
    @Expose() @IsString() @IsOptional() bucketRegion?: string
    @Expose() @IsString() @IsOptional() bigqueryRegion?: string
}

export class UpdateCompanyRequest {
    @Expose() @IsOptional() @IsString() @IsIn(CompanyTypeArray) companyType?: CompanyType
}
