import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'add-edit-footer',
  template: `
    <div class="footer">
      <button class='back-button' mat-flat-button (click)="back.emit()"><mat-icon>arrow_back</mat-icon> {{'Back'|translate}}</button>
      <button class="pull-right" mat-flat-button color="primary" (click)="save.emit()">{{'SaveAndContinue'|translate}} <mat-icon>arrow_forward</mat-icon></button>
    </div>
    `,
  styles: [`
  .back-button {
    border: 1px solid #215494;
    color: #215494;
  }
  .pull-right {
    float: right;
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #ccc;
    background-color: #fff;
    padding: 12px 60px;

    .mat-icon {
      margin: 0 2px;
      font-size: 22px;
    }
  }
  `]
})
export class AddEditFooterComponent implements OnInit {

  @Output() save = new EventEmitter()
  @Output() back = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

}
