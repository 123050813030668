<h2 mat-dialog-title>{{createUpdatetitle |translate}}</h2>
<p-toast key="resourceExists" position="bottom-center"></p-toast>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'CompanyType'|translate}}</mat-label>
        <mat-select placeholder="{{'ChooseYourOption'|translate}}" [(ngModel)]="companyType" (ngModelChange)="calc()"
            [required]="createUpdateMode === 'CREATE'">
            <mat-option *ngFor='let companyType of CompanyTypes' [value]="companyType">{{companyType}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <span *ngIf="createUpdateMode === 'CREATE'">
        <div>{{'Ctx'|translate}}</div>
        <mat-form-field>
            <span matPrefix style="color: gray;">{{ organizationId }}/</span>
            <input matInput [(ngModel)]="ctx" required (ngModelChange)="calc()">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Environment'|translate}}</mat-label>
            <input matInput [(ngModel)]="environmentId" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'ResourceName'|translate}}</mat-label>
            <input matInput [(ngModel)]="resourceName" disabled="true" required>
        </mat-form-field>
        <div>----------------</div>
        <div>A tool so may help to decide best regions <a href="https://cloud.withgoogle.com/region-picker/"> here</a></div>
        <div>----------------</div>
        <div>See all firestore options <a href="https://cloud.google.com/firestore/docs/locations"> here</a></div>
        <mat-form-field>
            <mat-label>{{'FirestoreRegion'|translate}}</mat-label>
            <input type="text" placeholder="{{'ChooseYourOption'|translate}}" aria-label="Number" matInput
                [(ngModel)]="firestoreRegion" [matAutocomplete]="autoFirestore" required>
            <mat-autocomplete autoActiveFirstOption #autoFirestore="matAutocomplete">
                <mat-option *ngFor='let fr of firestoreRegionOptions' [value]="fr[0]">
                    {{fr[0]}} {{fr[1]}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div>See all bucket options <a href="https://cloud.google.com/storage/docs/locations"> here</a></div>
        <mat-form-field>
            <mat-label>{{'BucketRegion'|translate}}</mat-label>
            <input type="text" placeholder="{{'ChooseYourOption'|translate}}" aria-label="Number" matInput
                [(ngModel)]="bucketRegion" [matAutocomplete]="autoBucket" required>
            <mat-autocomplete autoActiveFirstOption #autoBucket="matAutocomplete">
                <mat-option *ngFor='let br of bucketRegionOptions' [value]="br[0]">
                    {{br[0]}} {{br[1]}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div>See all bigquery options <a href="https://cloud.google.com/bigquery/docs/locations"> here</a></div>
        <mat-form-field>
            <mat-label>{{'BigqueryRegion'|translate}}</mat-label>
            <input type="text" placeholder="{{'ChooseYourOption'|translate}}" aria-label="Number" matInput
                [(ngModel)]="bigqueryRegion" (ngModelChange)="filterBigqueryRegions()" [matAutocomplete]="autoBigquery"
                required>
            <mat-autocomplete autoActiveFirstOption #autoBigquery="matAutocomplete">
                <mat-optgroup *ngFor="let group of bigqueryRegionsfiltered" [label]="group.label">
                    <mat-option *ngFor="let br of group.regions" [value]="br[1]">
                        {{br[0]}} <span style="margin-left: 20px;">{{br[1]}}</span><span style="float: right;"><img
                                *ngIf="br[2]" src="/assets/images/gleaf.svg" alt="Low co2" /></span>
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    </span>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close matTooltip="{{'CANCEL'|translate}}">
        <mat-icon color="primary">cancel</mat-icon>
    </button>
    <button mat-button (click)="openConfirmDialog()" matTooltip="{{'SaveChanges'|translate}}">
        <mat-icon color="primary">save</mat-icon>
    </button>
</mat-dialog-actions>