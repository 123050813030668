import { UserManagementService } from 'src/app/shared/services/user-management.service';
import { Component, OnInit } from '@angular/core';
import { Dal } from 'src/app/dal/dal';

import { GetUserInfoResponse } from 'src/app/shared/api-structures/admin/user';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  userInfo: GetUserInfoResponse | undefined
  _language: string
  uploadingFiles: {
    name: string,
    progress: number
  }[] = []

  constructor(
    private dal: Dal,
    private utilitiesService: UtilitiesService,
    private appService: AppService,
    private userManagementService: UserManagementService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.dal.getUserInfo().then(userInfo => this.userInfo = userInfo)
    this._language = this.userInfo?.language || 'en'
  }

  onLanguageChange(language: string) {
    this.dal.updateUserLanguage({ id: this.userInfo?.id, language })
  }

  async uploadPrimaryImage(event: { target: { files: File[] } }) {
    const file: File = event.target.files[0]

    if (this.utilitiesService.validateImageType(file) == false) {
      return
    }

    const uploadProgress = { name: file.name, progress: 0 }
    this.uploadingFiles = [uploadProgress]
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadingFiles[0].progress = progress)
    const resultFileCreation = await this.utilitiesService.isFileUrlExist(fileUrl)

    if (resultFileCreation === false) {
      this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
      return
    }

    await this.dal.updateUserProfileImage({ id: this.userInfo?.id, url: fileUrl })
    await this.dal.getUserInfo().then(userInfo => this.userInfo = userInfo)
    this.userManagementService.updateProfilePictureUrl(this.userInfo.profileImgUrl)
  }

  async removeImage() {
    await this.dal.removeUserProfileImage({ id: this.userInfo?.id })
    await this.dal.getUserInfo().then(userInfo => this.userInfo = userInfo)
  }

  uploadPrimaryImageBtnClick() {
    document.getElementById("singleFile").click()
  }
}
