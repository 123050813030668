import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { CustomerWithId } from 'src/app/shared/api-structures/admin/customer';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { CustomerAssociatedUsersDialogComponent } from 'src/app/shared/components/associations/associated-users/associated-users.component';
import { UpdateCustomerComponent } from '../update-customer/update-customer.component';
import _ from 'underscore';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';

@Component({
  selector: 'customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})

export class CustomerListComponent extends AutoDestroy implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  _customFields: CustomFieldSettingsWithId[]
  @Input() set customFields(v: CustomFieldSettingsWithId[]) {
    if (v) {
      this._customFields = v
      this.columnsToDisplay = [...this.columnsToDisplay.slice(0, 9), ...this.customFields.map(f => f.name), ...this.columnsToDisplay.slice(9)]
    }
  }
  get customFields() {
    return this._customFields
  }

  totalCustomers: number
  filter: string = ''

  isShowConfirmDeleteDialog: boolean
  dataSource = new MatTableDataSource<CustomerWithId>()
  columnsToDisplay = [
    'customerId',
    'name',
    'address',
    'territory',
    'category',
    'taxId',
    'chain',
    'area',
    'updatedAt',
    'externalId'
  ]

  customerToDelete: CustomerWithId = { name: '', category: '', id: '', type: '' }

  constructor(public dialog: MatDialog, private customerService: CustomerService) {
    super()
  }

  ngOnInit() {
    this.isShowConfirmDeleteDialog = false
    this.paginator.pageSize = 25
    this.subscriptions.push(this.customerService.listCustomers$.subscribe(d => {
      this.dataSource.data = d.items
      this.totalCustomers = d.totalItems
      this.paginator.length = this.totalCustomers
      this.dataSource.sort = this.sort
    }))
    // this.columnsToDisplay = [...this.columnsToDisplay, ...this.customFields.map(f => f.name)]
  }

  applyFilter() {
    this.getCustomers()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  async getCustomers() {
    const res = await this.customerService.loadCustomers({ currentPage: this.paginator.pageIndex, pageSize: this.paginator.pageSize, query: this.filter })
  }

  popupAssociatedUsers(customer: CustomerWithId) {
    const dialogRef = this.dialog.open(CustomerAssociatedUsersDialogComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      maxHeight: '95vh',
      data: { id: customer.id, name: customer.name, associationType: 'customer' }
    })
  }

  async updateCustomer(row: { id: string, externalId: string, name: string, category: string, address: string, territory: string, area: string, chain: string }) {
    const dialogRef = this.dialog.open(UpdateCustomerComponent, {
      panelClass: 'no-padding-dialog-container',
      data: { row, customFields: this.customFields }
    })

    dialogRef.afterClosed().subscribe(v => {
      if (v && v.isUpdated) {
        this.getCustomers()
      }
    })
  }

  ondeleteClick(id: string, name: string) {
    this.customerToDelete.id = id
    this.customerToDelete.name = name
    this.isShowConfirmDeleteDialog = true
  }

  async deleteUser() {
    await this.customerService.deleteCustomer({ id: this.customerToDelete.id })
    this.isShowConfirmDeleteDialog = false
    this.getCustomers()
  }

  getCustomFieldValue(row, field) {
    if (row.customFields) {
      const val = row.customFields[field] || ''
      return val
    }
    return ''
  }


  export() {
    const { data } = this.dataSource;
    if (!data || data.length === 0) {
      return;
    }
    const replacer = (key: string, value: any) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'customer_list.csv';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}