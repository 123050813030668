<div class="padding">
    <div style="color:#919191;margin-bottom: 16px;margin-left: 5px;">
        <a style="color:#919191;" routerLink="/organizationAdmin/companies">{{'CompaniesManagement'|translate}}</a>
        >
        <span style="font-weight: bold;">{{'CustomerManagement'|translate}}{{' ('}}{{companyId}})</span>
    </div>
    <div class="title">
        {{'Comapny'|translate}} ({{companyId}}) -
        {{'RegisteredCustomers'|translate}}
    </div>
    <div style='padding-right: 15px;padding-bottom: 20px;padding-left: 5px;'>
        <button matTooltip="{{'Refresh'|translate}}" mat-mini-fab color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
        <span style='padding: 10px;'></span>

        <button mat-mini-fab matTooltip="{{ 'ADD' | translate }}" color="primary" (click)="add()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <customer-list></customer-list>
</div>