<div class="table-main">
    <div class="table-content-container">
        <div class="table-title-container">
            <div class="table-title">
                {{planogramName}}{{' - '}}{{'AssociatedCustomers'|translate}}
            </div>
        </div>

        <div class="tabs">
            <mat-tab-group animationDuration="0ms" mat-stretch-tabs>
                <mat-tab label="{{'CUSTOMERS'|translate}}">
                    <associated-customers [id]="planogramId"></associated-customers>
                </mat-tab>

                <mat-tab label="{{'USERS'|translate}}">
                    <associated-users [id]="planogramId"></associated-users>
                </mat-tab>

                <mat-tab label="{{'Clusters'|translate|uppercase}}">
                    <associated-generic [displayedColumns]="['id','name','delete']" [fetch]="data.getAssociatedClusters"
                        [add]="data.addCluster" [delete]="data.removeCluster"
                        [fetchAddOptions]="data.getClusterOptions">
                    </associated-generic>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>