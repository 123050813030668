import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from "@angular/fire/compat";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { IConfig } from '../services/config.service';
import { PrimengModule } from '../shared/modules/primeng.module';
import { AddAssociatedCustomrComponent } from './components/associations/associated-customers/add-associated-customer/add-associated-customer.component';
import { AssociatedCustomersComponent } from './components/associations/associated-customers/associated-customers.component';
import { AddAssociatedUserToCustomerComponent } from './components/associations/associated-users/add-associated-user/add-associated-user.component';
import { CustomerAssociatedUsersDialogComponent } from './components/associations/associated-users/associated-users.component';
import { ExpireSessionDialogComponent } from './components/auth/expire-session-dialog/expire-session-dialog.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CreateCustomerComponent } from './components/customers/create-customer/create-customer.component';
import { CustomerListComponent } from './components/customers/customer-list/customer-list.component';
import { UpdateCustomerComponent } from './components/customers/update-customer/update-customer.component';
import { DragAndDropFilesComponent } from './components/drag-and-drop-files/drag-and-drop-files.component';
import { NetworkStatus } from './components/network-status';
import { CreateUserComponent } from './components/user-management/create-user/create-user.component';
import { UserManagementListComponent } from './components/user-management/list/user-management-list.component';
import { UpdateUserComponent } from './components/user-management/update-user/update-user.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { PermissionDirective } from './directives/permission.directive';
import { MaterielModule } from './modules/materiel.module';
import { SafePipe } from './pipes/safe.pipe';
import { UserManagementService } from './services/user-management.service';
import { GoogleMapsModule } from '@angular/google-maps'
import { MapComponent } from './components/map/map.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { SelectTableComponent } from './components/select-table/select-table.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { CustomFieldsComponent } from './components/custom-fields/custom-fields.component';
import { SimplePaginatorComponent } from './components/simple-paginator/simple-paginator.component';
import { AddEditFooterComponent } from './components/add-edit-footer/add-edit-footer.component';
import { AssociatedGenericComponent } from './components/associations/associated-generic/associated-generic.component';
import { AddAssociateComponent } from './components/associations/associated-generic/add-associate/add-associate.component';
import { ContentLoadingComponent } from './components/content-loading/content-loading.component';
import { RequestAccountDeletionComponent } from './components/request-account-deletion/request-account-deletion.component';
import { Capitalize } from './pipes/capitalize.pipe';
import { GenericTableComponent } from '../shared/components/generic-table/generic-table.component';
import { CustomFieldInputComponent } from './components/custom-field-input/custom-field-input.component';
import { OpenImageDialogComponent } from './components/open-image-dialog/open-image-dialog.component';
import { ListInputComponent } from './components/list-input/list-input.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { FoldableCardComponent } from './components/foldable-card/foldable-card.component';
import { GenericImportComponent } from './components/generic-import/generic-import.component';
import { ImportPartialComponent } from './components/generic-import/import-partial/import-partial.component';
import { ManageCustomerComponent } from '../admin/components/customer-management/manage-customer/manage-customer.component';
import { TextFilterPipe } from './pipes/textFilter.pipe';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { FormatPhonePipe } from './pipes/format-phone.pipe';

function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http) }

function getConfig(url: string) {
    const request = new XMLHttpRequest()

    request.open('GET', url, false)  // `false` makes the request synchronous
    request.send(null)

    if (request.status !== 200) {
        throw new Error('Error loading config')
    }

    const config = request.responseText
    const jsonConfig: IConfig = JSON.parse(config)
    const result = jsonConfig.firebase
    return result
}

const firebaseUiAuthConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [{
        requireDisplayName: false,
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    }],
    tosUrl: '<your-tos-link>',
    privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
    providers: [
        UserManagementService
    ],
    declarations: [
        NetworkStatus,
        UserManagementListComponent,
        UserManagementComponent,
        CreateUserComponent,
        UpdateUserComponent,
        AddAssociatedCustomrComponent,
        AssociatedCustomersComponent,
        CustomerAssociatedUsersDialogComponent,
        AddAssociatedUserToCustomerComponent,
        LoginComponent,
        LogoutComponent,
        PermissionDirective,
        ExpireSessionDialogComponent,
        DragAndDropFilesComponent,
        DragAndDropDirective,
        UpdateCustomerComponent,
        CreateCustomerComponent,
        ManageCustomerComponent,
        CustomerListComponent,
        ConfirmDialogComponent,
        MapComponent,
        AddressInputComponent,
        SelectTableComponent,
        CreatePasswordComponent,
        CustomFieldsComponent,
        SimplePaginatorComponent,
        AddEditFooterComponent,
        ContentLoadingComponent,
        AssociatedGenericComponent,
        AddAssociateComponent,
        RequestAccountDeletionComponent,
        Capitalize,
        GenericTableComponent,
        CustomFieldInputComponent,
        OpenImageDialogComponent,
        ListInputComponent,
        OutsideClickDirective,
        FoldableCardComponent,
        GenericImportComponent,
        ImportPartialComponent,
        SideNavComponent,
        FormatPhonePipe,
    ],
    imports: [
        FirebaseUIModule.forRoot(firebaseUiAuthConfig),
        AngularFireModule.initializeApp(getConfig('./configs/config.json')),
        NgxSpinnerModule,
        MaterielModule,
        FormsModule,
        ReactiveFormsModule,
        PrimengModule,
        BrowserModule,
        GoogleMapsModule,
        HttpClientJsonpModule,
        SafePipe,
        TextFilterPipe,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        NetworkStatus,
        UserManagementListComponent,
        UserManagementComponent,
        CreateUserComponent,
        UpdateUserComponent,
        AddAssociatedCustomrComponent,
        AssociatedCustomersComponent,
        CustomerAssociatedUsersDialogComponent,
        AddAssociatedUserToCustomerComponent,
        LogoutComponent,
        PermissionDirective,
        DragAndDropFilesComponent,
        SafePipe,
        UpdateCustomerComponent,
        CreateCustomerComponent,
        CustomerListComponent,
        ConfirmDialogComponent,
        MapComponent,
        FormsModule,
        SelectTableComponent,
        SimplePaginatorComponent,
        AddEditFooterComponent,
        ContentLoadingComponent,
        AssociatedGenericComponent,
        Capitalize,
        GenericTableComponent,
        CustomFieldInputComponent,
        ListInputComponent,
        FoldableCardComponent,
        GenericImportComponent,
        TextFilterPipe,
        SideNavComponent,
        FormatPhonePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
