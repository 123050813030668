import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchProductsItemWithId } from 'src/app/shared/api-structures/misc/catalog';
import { SearchProductsRequest } from 'src/app/shared/api-structures/misc/product';
import { SelectTableFetchFunction } from 'src/app/shared/interfaces';
import { Dal } from '../../../../dal/dal';

@Component({
  selector: 'app-select-product-dialog',
  template: `
  <app-select-table [fields]="['thumbnailUrl', 'id', 'name', 'category', 'brand']" [fetchData]="fetchProducts"
  (select)="data.onSelect($event)"></app-select-table>`,
})
export class SelectProductDialogComponent implements OnInit {

  constructor(private dal: Dal, @Inject(MAT_DIALOG_DATA) public data: { onSelect: (data: { id: string, name: string, thumbnailUrl: string }) => void }) { }

  ngOnInit(): void {
  }

  fetchProducts: SelectTableFetchFunction<SearchProductsItemWithId> = async ({ page, pageSize, textFilter, sortDirection, sortBy }) => {
    let sortByPT = sortBy
    if (sortByPT === 'id') sortByPT = 'none'
    const res = await this.dal.searchProducts(page, pageSize, [textFilter], {}, false, sortByPT as SearchProductsRequest['sortBy'], sortDirection || 'desc')
    return { totalItems: res.totalProducts, items: res.items.map(item => ({ ...item, id: item.productId })) }
  }
}