<div style="padding: 24px; background-color: white;">
  <div class="wrapper">
    <div style="padding:  12px 24px;">
      <mat-form-field>
        <mat-label>{{'Search'|translate}}</mat-label>
        <input matInput (keyup.enter)="searchUsers($event.target.value)">
      </mat-form-field>
    </div>
    <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
        <td mat-cell *matCellDef="let row"> <span style="display:none">{{row.id}}</span>{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Email'|translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Phone'|translate}}</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.phone">
            <span class="fi fi-{{getCountryCode(row.phone)}}" style="margin-right: 8px;"></span>
            {{row.phone | formatPhone}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Role'|translate}}</th>
        <td mat-cell *matCellDef="let row">{{row.role}}</td>
      </ng-container>

      <ng-container matColumnDef="deleteIcon">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td style="padding-right: 0;" mat-cell *matCellDef="let row" class="delete-cell">
          <button matTooltip="{{'Delete'|translate}}" mat-button
            (click)="onDeleteClick(row.id, row.name, row.email, row.phone)">
            <mat-icon style="transform: scale(0.9);">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
    </table>
  </div>
</div>