import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { saveAs } from "file-saver";
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ListPriceTargetsRequest, PriceTargetFull } from 'src/app/shared/api-structures/misc/priceTarget';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LanguageService } from 'src/app/shared/services/language.service';
import { PriceTargetService } from '../../services/price-target.service';
import { PriceTargetFormComponent } from './price-target-form/price-target-form.component';
import { Dal } from 'src/app/dal/dal';
import { AppService } from 'src/app/services/app.service';
import { mockCsvData } from './csvTemplateData'

@Component({
  selector: 'app-price-target',
  templateUrl: './price-target.component.html',
  styleUrls: ['./price-target.component.scss']
})
export class PriceTargetComponent extends AutoDestroy implements OnInit {
  moment = moment;
  displayedColumnsCustomer = ['image', 'productInfo', 'minPrice', 'basePrice', 'maxPrice', 'customerArea',
  'customerTerritory', 'customerCategory', 'customerChain', 'dateFrom', 'dateTo', 'lastUpdate',
  'createdAt', 'edit', 'copy', 'delete'
]
  priceTargets: PriceTargetFull[] = []
  totalItems = 2
  dataSource: MatTableDataSource<PriceTargetFull>;
  filters: string[] = [];
  displayedColumns: (keyof PriceTargetFull | 'edit' | 'delete')[] = ['id', 'productName', 'price', 'customerArea', 'customerTerritory', 'customerCategory', 'customerChain', 'dateFrom', 'dateTo', 'lastUpdate', 'createdAt', 'edit', 'delete'];
  query: ListPriceTargetsRequest = {
    currentPage: 0,
    pageSize: 25,
    textFilter: ''
  }
  readonly filterSeparatorKeysCodes: number[] = [ENTER, COMMA];
  importDialogData: any = {
    template:mockCsvData,
    title:'PriceTargets',
    id:'priceTargets'
  }

  constructor(
    private priceTargetService: PriceTargetService,
    private languageService: LanguageService,
    private appService: AppService,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    private dal: Dal
  ) {
    super()
  }

  async ngOnInit() {
    const companySettings = await this.dal.getCompanySettings()
    moment.locale(companySettings.language ?? 'en')
    this.subscriptions.push(this.priceTargetService.priceTargets$.subscribe((data) => {
      this.priceTargets = data.items
      this.priceTargets.map(i => {
        i.priceLabel = i.price.toLocaleString((companySettings.currency === 'USD' || companySettings.currency === 'EUR') ? 'en' : (companySettings.language ?? 'en'), {style: 'currency', currency: companySettings.currency ?? 'USD'})
        i.minPriceLabel = this.convertCurrency(i.minPrice, companySettings.currency, companySettings.language)
        i.maxPriceLabel = this.convertCurrency(i.maxPrice, companySettings.currency, companySettings.language)
      })
      this.totalItems = data.totalItems
      this.dataSource = new MatTableDataSource(this.priceTargets);
    }))
    this.priceTargetService.listPriceTargets(this.query)
  }

  private convertCurrency(price: number | undefined | null, currency: string, language: string) {
    let lang = language ?? 'en'
    let curr = currency ?? 'USD'
    if (typeof(price) === 'number') {
      if (curr === 'USD' || 'EUR') {
        lang = 'en'
      }
      return price.toLocaleString(lang, {style: 'currency', currency: curr})
    }
    return ''
  }

  onFilter(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value
    this.query.textFilter = value
    this.priceTargetService.listPriceTargets(this.query)
  }

  onSort({ active, direction }) {
    if (!direction) {
      this.query.sortBy = null
      this.query.sort = null
    } else {
      this.query.sortBy = active
      this.query.sort = direction
    }
    this.priceTargetService.listPriceTargets(this.query)
  }

  onPaginate({ pageIndex, pageSize }) {
    this.query.currentPage = pageIndex
    this.query.pageSize = pageSize
    this.priceTargetService.listPriceTargets(this.query)
  }

  addNew() {
    const dialogRef = this.dialog.open(PriceTargetFormComponent, {
      width: '60vw',
    });
    const instance = dialogRef.componentInstance
    instance.mode = 'add'

    dialogRef.afterClosed().subscribe(async priceTarget => {
      if (!priceTarget) {
        return;
      }

      this.priceTargetService.addPriceTarget(priceTarget).then(async () => {
        await this.priceTargetService.listPriceTargets(this.query)
        this.snackBar.openSnackBar(3000, this.languageService.translateSync('PriceTargetAdded'))
      })
    })
  }

  onEdit(id: string) {
    const toEdit = this.priceTargets.find(x => x.id === id)
    const dialogRef = this.dialog.open(PriceTargetFormComponent, {
      width: '60vw',
    });
    const instance = dialogRef.componentInstance
    instance.mode = 'edit'
    instance.selectedPriceTarget = toEdit

    dialogRef.afterClosed().subscribe(async priceTarget => {
      if (!priceTarget) {
        return;
      }

      this.priceTargetService.updatePriceTarget({ id: toEdit.id, data: priceTarget }).then(async () => {
        await this.priceTargetService.listPriceTargets(this.query)
        this.snackBar.openSnackBar(3000, this.languageService.translateSync('PriceTargetEdited'))
      })
    })
  }

  onDelete(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: {
        title: `${this.languageService.translateSync('ConfirmDeletionTitle')}`,
        message: `${this.languageService.translateSync('DeletePriceTarget')} '${id}'?`
      }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }
      await this.priceTargetService.deletePriceTarget(id)
      await this.priceTargetService.listPriceTargets(this.query)
    })
  }
 
  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  openCsv() {
    (document.querySelector("#importCsvFileInput") as HTMLElement).click();
  }

  async listPriceTargets() {
    this.priceTargetService.listPriceTargets(this.query)
  }

  async exportPriceTargets() {
    const res = await this.priceTargetService.exportPriceTargets()
    saveAs(res.url, "price-targets.csv")
  }

}
