import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CompanySettings } from 'src/app/shared/api-structures/admin/companySettings';
import { Context } from 'src/app/shared/api-structures/admin/user';
import { CreateCompanyRequest, CreateCompanyResponse, ListCompaniesRequest, ListCompaniesResponse, UpdateCompanyRequest } from 'src/app/shared/api-structures/super-admin/company';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private listCompanies = new Subject<ListCompaniesResponse>()
  listCompanies$ = this.listCompanies.asObservable()

  constructor(private api: ApiService) { }

  async createCompany(body: CreateCompanyRequest) {
    const response = await this.api.superRequest<CreateCompanyRequest, CreateCompanyResponse>({
      endpoint: '/createCompany',
      body,
      responseType: CreateCompanyResponse
    })
    return response
  }

  async updateCompany(context: Context, body: UpdateCompanyRequest) {
    const response = await this.api.superRequest({
      endpoint: '/updateCompany',
      body,
      context,
      responseType: UpdateCompanyRequest
    })
    return response
  }

  async deleteCompany(body: Context) {
    await this.api.superRequest({ endpoint: '/deleteCompany', body })
  }

  async getCompanySettings(body: Context) {
    const companySettings = await this.api.superRequest({ endpoint: '/getCompanySettings', body, responseType: CompanySettings.Item })
    return companySettings
  }

  async saveCompanySettings(context: Context, body: CompanySettings.Item) {
    const response = await this.api.superRequest({
      endpoint: '/saveCompanySettings',
      body,
      context,
      responseType: CompanySettings.Item
    })
    return response
  }

  async loadCompanies(organizationId: string, body: ListCompaniesRequest) {
    const data = await this.api.superRequest({
      endpoint: '/listCompanies',
      body: body,
      context: { organization: organizationId },
      responseType: ListCompaniesResponse
    })
    this.listCompanies.next(data)
    return data
  }

  async getRegions() {
    //TODO
  }
}
