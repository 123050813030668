import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class Capitalize implements PipeTransform {

  transform(str: string) {
    if (!str) return str
    const result = str.charAt(0).toUpperCase() + str.slice(1)
    return result
  }
}