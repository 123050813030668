import { Component, OnInit, ViewChild } from '@angular/core';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ListUsersForAdmin } from 'src/app/shared/api-structures/admin/user';
import { UserManagementService } from 'src/app/shared/services/user-management.service';
import { UpdateUserComponent } from '../update-user/update-user.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { AssociatedCustomersComponent } from '../../associations/associated-customers/associated-customers.component';

@Component({
  selector: 'user-management-list',
  templateUrl: './user-management-list.component.html',
  styleUrls: ['./user-management-list.component.scss'],
})
export class UserManagementListComponent extends AutoDestroy implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  totalUsers: number
  filter: string = ''
  userToDelete: ListUsersForAdmin.Item = {
    name: '',
    email: '',
    phone: '',
    id: '',
    role: 'mobile',
  }
  isShowConfirmDeleteDialog: boolean
  dataSource = new MatTableDataSource<ListUsersForAdmin.Item>()
  columnsToDisplay = [
    'name',
    'email',
    'phone',
    'role',
    'associatedUsers',
    'editIcon',
    'deleteIcon'
  ]

  constructor(private userManagementService: UserManagementService,
    public dialog: MatDialog,
  ) { super() }

  ngOnInit() {
    this.isShowConfirmDeleteDialog = false
    this.paginator.pageSize = 25;
    this.subscriptions.push(this.userManagementService.listUsers$.subscribe(d => {
      this.dataSource.data = d.items
      this.totalUsers = d.totalUsers
      this.paginator.length = this.totalUsers
      this.dataSource.sort = this.sort;
    }))
  }

  applyFilter() {
    this.getUsers()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async getUsers() {
    const res = await this.userManagementService.loadUsers({ currentPage: this.paginator.pageIndex, pageSize: this.paginator.pageSize, query: this.filter })
  }

  ondeleteClick(id: string, name: string, email: string, phone: string) {
    this.userToDelete.id = id
    this.userToDelete.name = name
    this.userToDelete.email = email
    this.userToDelete.phone = email
    this.isShowConfirmDeleteDialog = true
  }
  async deleteUser() {
    await this.userManagementService.deleteUser({ id: this.userToDelete.id })
    this.isShowConfirmDeleteDialog = false
    this.getUsers()
  }

  async updateUser(id: string, email: string, name: string, role: string, phone: string) {
    const dialogRef = this.dialog.open(UpdateUserComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      data: { id, email, name, role, phone, displayUserRole: this.displayUserRole }
    });

    dialogRef.afterClosed().subscribe(v => {
      if (v && v.isUpdated) {
        this.getUsers()
      }
    })
  }

  popupAssociatedCustomers(user: ListUsersForAdmin.Item) {
    const dialogRef = this.dialog.open(AssociatedCustomersComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      maxHeight: '95vh',
      data: { id: user.id, name: user.name, associationType: 'user' }
    })
  }

  displayUserRole(role: string | undefined) {
    if(!role) {
      return ''
    }
    
    if(role.toLowerCase() === 'mobile') {
      return 'ImageSupervisor'
    }

    if(role.toLowerCase() === 'simple mobile') {
      return 'Retailer'
    }

    return role
  }
}
