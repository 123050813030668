<div class="container_">
  <div class="center-wrapper">
    <div class="row">
      <div class="left-text">
        <h1 *ngIf="userInfo">Hey, <span *ngIf="userInfo else noname">{{userInfo.name}}
            <span #noname>noName</span>
          </span>
        </h1>
        <p>{{"welcomeSuperAdmin" | translate }}</p>
      </div>

    </div>

    <div class="row">
      <div class="card" *ngFor="let item of cardItems; let i = index">
        <div class="card-content">
          <p class="text-card-1">{{item.title | translate | uppercase }}</p>
          <h2 class="text-card-2">{{item.amount}} <span *ngIf="greaterThanMax(item.amount)"><mat-icon
                class="md-18">add</mat-icon></span></h2>
        </div>
        <div class="card-icon">
          <mat-icon>{{item.icon}}</mat-icon>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="card card-table" *ngFor="let table of cardItems">
        <div class="wrapper">
          <div class="table-header">
            <div class="header-wrapper">
              <h3 class="h3-title" style="font-size: 16px; margin-bottom: 0;">{{table.title | translate }}</h3>
              <a (click)="navigateToPage(table.title)" mat-button *ngIf="table.title !== 'Companies'"
                style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; padding: 0;">
                {{"seeAll" | translate }}&nbsp;{{table.title | translate}}
                <mat-icon class="arrow">arrow_forward</mat-icon>
              </a>
            </div>
          </div>
          <table mat-table [dataSource]="table.dataSource">
            <ng-container *ngFor="let column of getColumns(table.dataSource)" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef> {{ getDisplayColumn(column) | translate | capitalize}} </th>
              <td mat-cell *matCellDef="let element"> {{ element[column] | translate }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="getColumns(table.dataSource)"></tr>
            <tr mat-row *matRowDef="let row; columns: getColumns(table.dataSource);"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>