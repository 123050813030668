import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from 'src/app/admin/services/customer.service';
import { UserCustomerService } from 'src/app/admin/services/user-customer.service';
import { Dal } from 'src/app/dal/dal';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CustomerWithId } from 'src/app/shared/api-structures/admin/customer';
import { SortDirection } from 'src/app/shared/api-structures/common';

@Component({
  selector: 'associated-customers',
  templateUrl: './associated-customers.component.html',
  styleUrls: ['./associated-customers.component.scss'],
})
export class AssociatedCustomersComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @Input() id: string

  displayedColumns: string[] = ['externalId', 'name', 'category', 'deleteIcon']
  dataSource: MatTableDataSource<CustomerWithId>
  associatedCustomers: CustomerWithId[] = []
  totalAssociatedCustomers: number;

  sortBy: string;
  sortDirection: SortDirection
  filter: string = ''

  associationType: 'user' | 'planogram'
  customerId: string
  userName: string

  isShowAddCostomerField: boolean = false
  isShowConfirmDeleteDialog = false

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService,
    private userCustomerService: UserCustomerService,
    private dal: Dal,
    private customerService: CustomerService) {
    this.userName = data.name
    this.associationType = data.associationType ?? 'planogram'
    if (this.associationType === 'user') {
      this.id = data.id
    }
  }

  ngOnInit() {
    this.paginator.pageSize = 25
    const currentPage = this.paginator.pageIndex
    const pageSize = this.paginator.pageSize
    this.getAssociatedCustomers(currentPage, pageSize, this.filter)
  }

  sortData(sort: Sort) {
    this.sortBy = sort.active;
    this.sortDirection = (sort.direction as SortDirection)
    this.applyFilter()
    this.sortBy = ''
    this.sortDirection = null
  }

  applyFilter() {
    this.paginator.pageIndex = 0
    this.getAssociatedCustomers(this.paginator.pageIndex, this.paginator.pageSize, this.filter)
  }

  async getAssociatedCustomers(currentPage: number, pageSize: number, filter: string) {
    const res = await this.getAssociatedCustomersByAssociationType(currentPage, pageSize, filter)
    this.associatedCustomers = res.items
    this.totalAssociatedCustomers = res.totalItems
    this.dataSource = new MatTableDataSource(this.associatedCustomers)
    this.dataSource.sort = this.sort
    this.paginator.length = this.totalAssociatedCustomers
  }

  async getAssociatedCustomersByAssociationType(currentPage: number, pageSize: number, filter: string) {
    switch (this.associationType) {
      case 'user': return await this.userCustomerService.listAssociatedCustomersToUser({ userId: this.id, currentPage, pageSize, filter })
      case 'planogram': return await this.dal.listAssociatedCustomersToPlanogram({ planogramId: this.id, currentPage, pageSize, filter })
    }
  }

  closeAddCustomer() {
    this.filter = ''
    this.getAssociatedCustomers(this.paginator.pageIndex, this.paginator.pageSize, this.filter)
  }

  onCustomerSelected(filter: string) {
    this.filter = filter
    this.applyFilter()
  }

  ondeleteClick(customerId: string) {
    this.customerId = customerId
    this.isShowConfirmDeleteDialog = true
  }

  async deleteUserCustomer() {
    this.isShowConfirmDeleteDialog = false

    switch (this.associationType) {
      case 'user': await this.userCustomerService.deleteUserCustomer({ customerId: this.customerId, userId: this.id })
        break
      case 'planogram': await this.dal.deleteAssociatedCustomresToPlanogram({ planogramId: this.id, customerId: this.customerId })
        break
    }
    this.getAssociatedCustomers(this.paginator.pageIndex, this.paginator.pageSize, this.filter)
    this.snackbarService.openSnackBar(2000, `Customer was successfully deleted from ${this.associationType}`)
  }

}
