import { Component } from "@angular/core";
import { SnackbarService } from "src/app/services/snackbar.service";
import { AutoDestroy } from "../base-directives/auto-destroy";
import { ApiTrackerService } from "../services/api.tracker.service";


@Component({
    selector: 'network-status2',
    templateUrl: './network-status2.html',
})
export class NetworkStatus2 extends AutoDestroy {
    showSpinner = false

    constructor(apiTrackerService: ApiTrackerService, private snackbar: SnackbarService) {
        super()

        this.showSpinner = apiTrackerService.isSpinnerNeeded

        this.subscriptions.push(apiTrackerService.onSpinnerNeededChanged$.subscribe(d => this.showSpinner = d.isShow))
        this.subscriptions.push(apiTrackerService.onError$.subscribe(d => this.snackbar.openSnackBar(2000, d.error)))
    }
}
