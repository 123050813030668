import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IPosition } from '../shared/api-structures/mobile';
import { LanguageService } from '../shared/services/language.service';
import { SnackbarService } from './snackbar.service';
import imageCompression from 'browser-image-compression';

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private snackbarService: SnackbarService,
    private http: HttpClient) { }

  cloneArray<T>(array: unknown[]) {
    return array.map(o => Object.assign({}, o) as T);
  }

  cloneObject(object: any) {
    return Object.assign({}, object);
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getUrlParams(url: string) {
    var params = {};
    var paramsStr = url.split("?")[1];
    if (!paramsStr) {
      return params;
    }
    var paramsArray = paramsStr.split("&");
    paramsArray.forEach(p => {
      var key = p.split("=")[0];
      var value = p.split("=")[1];
      params[key] = value
    })
    return params
  }

  dataUrlToFile(dataurl: string, filename: string) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      // TODO replace atob with Buffer.from
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  addFiltersToUrl(queryParams: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  replaceAll(str: string, strFrom: string, strTo: string) {
    var find = strFrom;
    var re = new RegExp(find, 'g');
    return str.replace(re, strTo);
  }

  replaceParenthesisChar(str: string, strFrom: string, strTo: string) {
    let newStr = str.replace(strFrom, strTo);
    if (newStr.includes(strFrom)) {
      return this.replaceParenthesisChar(newStr, strFrom, strTo)
    }
    else {
      return newStr
    }
  }

  replaceAllParenthesisChar(str: string, strTo: string) {
    if (!str.includes("(") && !str.includes(")")) {
      return str;
    }
    if (str.includes("(")) {
      str = this.replaceParenthesisChar(str, "(", strTo)
    }
    if (str.includes(")")) {
      str = this.replaceParenthesisChar(str, ")", strTo)
    }
    return str;
  }

  getImage(url: string) {
    let image = new Image();
    image.crossOrigin = 'Anonymous'
    image.src = url
    return image;
  }

  toHexString(byteArray: Uint8Array) {
    const result = (Array.prototype.map.call(byteArray, v => v.toString(16).padStart(2, '0')) as string[]).join('')
    return result
  }

  validateImageType(file: File) {
    const valid = ['png', 'jpg', 'jpeg']
    const extension = file.name.split('.').pop().toLowerCase()
    if (valid.includes(extension) && valid.includes(file.type.split('/')[1].toLowerCase().trim())) {
      return true
    }

    const errorMessage = this.languageService.translateSync('ImagetTypeError')
    this.snackbarService.openSnackBar(5000, errorMessage);
    return false
  }

  async isFileUrlExist(fileName: string) {
    let fileStatus = await this.http.get(fileName, { responseType: 'blob' }).pipe().toPromise().then((response) => {
      return true
    },
      (error: HttpErrorResponse) => {
        return false
      })

    if (fileStatus === false) {
      const errorMessage = this.languageService.translateSync('CorruptedImage')
      this.snackbarService.openSnackBar(5000, errorMessage)
    }
    return fileStatus;
  }
  roundToDecimal(num: number, decimal = 1) {
    return Math.round(num * 10 ** decimal) / 10 ** decimal
  }

  toHex(str: string) {
    let result = ''
    for (let i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16)
    }
    return result
  }

  strToColor(str: string, opacity = 1, colors?: string[]) {
    const defaultColors = ['#00FFFF', '#7FFFD4', '#0000FF', '#8A2BE2', '#A52A2A', '#5F9EA0', '#008B8B', '#8B008B', '#7FFF00', '#2F4F4F', '#FF00FF', '#ADFF2F', '#008000', '#7CFC00', '#F08080', '#FF00FF', '#7B68EE']
    if (!colors) { colors = defaultColors }
    const hexStr = this.toHex(str)
    let hex = 0
    for (let i = 0; i < hexStr.length; i++) {
      hex += parseInt(hexStr[i], 16)
    }
    let color = colors[hex % colors.length]
    if (opacity !== 1) { color = this.colorToRgba(color, opacity) }
    return color
  }

  colorToRgba(color: string, opacity: number) {
    let result = ''
    if (color.startsWith('#')) {
      const ex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
      result = ex ? `rgba(${parseInt(ex[1], 16)},${parseInt(ex[2], 16)},${parseInt(ex[3], 16)},${opacity})` : ''
    } else if (color.startsWith('rgb(')) {
      result = color.replace(')', `,${opacity})`)
    } else if (color.startsWith('rgba(')) {
      result = color.replace(/[^,]+(?=\))/, opacity.toString())
    }
    if (!result) {
      throw new Error('Invalid color: ' + color)
    }
    return result
  }

  percentOf(part: number, full: number) {
    return (part / full) * 100
  }

  deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
  }

  randomStr(len: number, charSet?: string) {
    charSet =
      charSet ||
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < len; i++) {
      let randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  rectOverlap(rect1: IPosition, rect2: IPosition) {
    if (rect1.left > rect2.right || rect2.left > rect1.right) {
      return false;
    }

    if (rect1.top > rect2.bottom || rect2.top > rect1.bottom) {
      return false;
    }

    return true;
  }

  async getCountries() {
    return this.http.get('https://servicodados.ibge.gov.br/api/v1/paises/{paises}')
  }

  async compressImageFileIfNeeded(file: File) {
    return new Promise<File>((resolve, reject) => {
      const img = new Image()
      img.src = URL.createObjectURL(file)

      img.onload = async () => {
        this.compressImgOnload(resolve, reject, img, file)
      }

      img.onerror = function (error) {
        console.log('Error: ', error)
        reject(error)
      }
    })

  }

  private async compressImgOnload(resolve, reject, img, file) {
    const compressOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
    }
    if (img.width <= 1000 && img.height <= 1000 && file.size < compressOptions.maxSizeMB * 1024 * 1024) {
      resolve(file)
      return
    }

    try {
      const compressedFile = await imageCompression(file, compressOptions)
      resolve(compressedFile)
    } catch (error) {
      console.log(error)
      reject(error)
    }
  }

  async saveFile(blob: Blob, fileName: string) {
    const fileUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = fileUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  jsonToCsv(items: any[]) {
    if(items.length === 0) {
      return ''
    }
    const replacer = (_, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')
    return csv
  }
}
