import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CompaniesService } from 'src/app/organization-admin/services/org-admin-companies.service';
import { Company } from 'src/app/shared/api-structures/super-admin/company';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CompanySettingsComponent } from 'src/app/organization-admin/components/companies-management/company-settings/company-settings.component';
@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
})
export class CompaniesListComponent extends AutoDestroy implements OnInit {
  @Input() organizationId: string
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  totalCompanies: number
  filter: string = ''

  dataSource = new MatTableDataSource<Company>()
  columnsToDisplay = [
    'comapnyId',
    'environment',
    'name',
    'customers',
    'updatedAt',
    'customersIcon',
    'settings',
  ]
  CompanyToDelete: { id: string, environmentId: string }
  isShowConfirmDeleteDialog: boolean

  constructor(private companiesService: CompaniesService, private dialog: MatDialog,
    private languageService: LanguageService,) {
    super()
  }

  ngOnInit() {
    this.isShowConfirmDeleteDialog = false
    this.paginator.pageSize = 25;
    this.subscriptions.push(this.companiesService.listCompanies$.subscribe(d => {
      this.dataSource.data = d.items
      this.totalCompanies = d.totalProducts
      this.paginator.length = this.totalCompanies
      this.dataSource.sort = this.sort
    }))
  }

  applyFilter() {
    this.getCompanies()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  async getCompanies() {
    const res = await this.companiesService.loadCompanies({ currentPage: this.paginator.pageIndex, pageSize: this.paginator.pageSize, query: this.filter })
  }

  popupSettings(organizationId, environmentId, companyId ) {
    const dialogRef = this.dialog.open(CompanySettingsComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      data: { companyId, environmentId, organizationId }
    })
  }
}