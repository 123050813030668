<div class="blue-background">
  <div class="container">
    <div class="row" style="display: flex; justify-content: space-between; margin-left: 0px; width: 100%;">
      <span class="title"><a class="icon-anchor"[routerLink]="['/home']"><i class="pi pi-arrow-left mr-2"></i></a> {{'MySettings'|translate}}</span>
    </div>
    <div class="table">
      <mat-tab-group animationDuration="0ms" dynamicHeight>
        <mat-tab label="{{'ProfileDetails'|translate}}">
          <ng-template matTabContent>
            <div class="main-square">
              <div class="user-info-container">
                <mat-form-field>
                  <input matInput placeholder="{{'Name'|translate}}" formControlName="name" [value]="userInfo?.name" disabled>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="{{'Company'|translate}}" formControlName="company" [value]="userInfo?.company" disabled>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="{{'Role'|translate}}" formControlName="role" [value]="userInfo?.role" disabled>
                </mat-form-field>

                <mat-divider></mat-divider>

                <mat-form-field [style]="{
                  marginTop: '20px'
                }">
                  <mat-label>{{'Language'|translate}}</mat-label>
                  <mat-select [(ngModel)]="_language" (ngModelChange)="onLanguageChange($event)">
                      <mat-option value="pt"><span class="fi fi-br"></span> Portuguese</mat-option>
                      <mat-option value="en"><span class="fi fi-us"></span> English</mat-option>
                      <mat-option value="es"><span class="fi fi-es"></span> Spanish</mat-option>
                      <mat-option value="ar"><span class="fi fi-sa"></span> Arabic</mat-option>
                      <mat-option value="el"><span class="fi fi-gr"></span> Greek</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="user-photo-container">
                <h6>{{'ProfilePhoto'|translate}}</h6>
                <div class="user-photo">
                  <img *ngIf="!!userInfo?.profileImgUrl && userInfo?.profileImgUrl !== 'default'" src="{{ userInfo?.profileImgUrl }}">
                  <img *ngIf="!userInfo?.profileImgUrl || userInfo?.profileImgUrl === 'default'" src="../../../assets/images/default_profile_picture.png">
                </div>
                <div style="display: flex; place-content: space-between; margin-top: '20px'; margin-bottom: '20px';  gap: 8px;">
                  <button [disabled]="!userInfo.profileImgUrl || userInfo.profileImgUrl === 'default'" style="color: rgba(107, 107, 107, 1); width: 100%;" mat-stroked-button (click)="removeImage()">
                    {{'REMOVE'|translate|uppercase}}
                  </button>
                  <input 
                    type="file" 
                    accept=".gif, .jpg, .png" 
                    id="singleFile" 
                    (change)="uploadPrimaryImage($event)"
                    [hidden]="true"
                  >
                  <button 
                    style="background-color: rgba(51, 100, 162, 1); color: white; width: 100%;" 
                    mat-button
                    (click)="uploadPrimaryImageBtnClick()"
                    >
                    {{'Upload'|translate|uppercase}}
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>